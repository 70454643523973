import NProgress from "@/config/nprogress";
import axios, { AxiosInstance, AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
// import { showFullScreenLoading, tryHideFullScreenLoading } from "@/config/serviceLoading";
import { ResultData } from "@/api/interface";
import { ResultEnum } from "@/enums/httpEnum";
import { checkStatus } from "./helper/checkStatus";
import { AxiosCanceler } from "./helper/axiosCancel";
import { setToken } from "@/redux/modules/global/action";
import { message, Modal } from "antd";
import { store } from "@/redux";
// import { HOME_URL } from "@/config/config";
import { rsaPrivateData, rsaPublicData } from "@/utils/pubilc/rsa";
import { aesDecrypt, aesEncrypt, generatekey } from "@/utils/pubilc/aes";

const axiosCanceler = new AxiosCanceler();

const config = {
	// 默认地址请求地址，可在 .env 开头文件中修改
	// baseURL: apiUrl,
	// 设置超时时间（10s）
	timeout: 30000,
	// 跨域时候允许携带凭证
	withCredentials: false
};

let isOpen = false;

class RequestHttp {
	service: AxiosInstance;
	public constructor(config: AxiosRequestConfig) {
		// 实例化axios
		this.service = axios.create(config);
		/**
		 * @description 请求拦截器
		 * 客户端发送请求 -> [请求拦截器] -> 服务器
		 * token校验(JWT) : 接受服务器返回的token,存储到redux/本地储存当中
		 */
		this.service.interceptors.request.use(
			(config: AxiosRequestConfig) => {
				const objTemp: any = {}
				const key = generatekey();
				// console.log("--------运行环境----------")
				// console.log(import.meta.env.MODE)
				// console.log("--------运行环境----------")
				if (import.meta.env.MODE != "devprod") {
					console.log("接口"+config.url+"--------加密前请求参数----------")
					console.log(config.data)
				}
				objTemp.data = aesEncrypt(JSON.stringify(config.data), key)
				// 密钥加密
				objTemp.key = rsaPublicData(key)
				config.data = objTemp
				// * 将当前请求添加到 pending 中
				axiosCanceler.addPending(config);
				// * 如果当前请求不需要显示 loading,在api服务中通过指定的第三个参数: { headers: { noLoading: true } }来控制不显示loading，参见loginApi
				// config.headers!.noLoading || showFullScreenLoading();
				config.headers!.noLoading || NProgress.start();
				const token: string = store.getState().global.token;
				return { ...config, headers: { ...config.headers, "token": token } };
			},
			(error: AxiosError) => {
				return Promise.reject(error);
			}
		);

		/**
		 * @description 响应拦截器
		 *  服务器换返回信息 -> [拦截统一处理] -> 客户端JS获取到信息
		 */
		this.service.interceptors.response.use(
			(response: AxiosResponse) => {
				const { data, config } = response;
				let option = JSON.parse(aesDecrypt(data.data, rsaPrivateData(data.key)));
				NProgress.done();
				// * 在请求结束后，移除本次请求(关闭loading)
				axiosCanceler.removePending(config);
				// tryHideFullScreenLoading();

				// alert(data.code);
				// * 登录失效（code == 599）
				if (option.code == ResultEnum.OVERDUE) {
					store.dispatch(setToken(""));
					if (!isOpen) {
						const modal = Modal.error({});
						modal.update({
							title: "错误编码:" + option.code,
							// content: data.msg + ',请重新登录。',
							content: '账号未登录或者登录过期,请登录。',
						});
						isOpen = true;
						setTimeout(() => {
							isOpen = false;
						}, 1000)
					}
					window.location.hash = "/login";
					// return Promise.reject(data);
				}
				// * 全局错误信息拦截（防止下载文件得时候返回数据流，没有code，直接报错）
				if (option.code && option.code !== ResultEnum.SUCCESS) {
					if (!isOpen) {
						const modal = Modal.error({});
						modal.update({
							title: "错误编码:" + option.code,
							content: option.msg,
						});
						isOpen = true;
						setTimeout(() => {
							isOpen = false;
						}, 1000)
					}
					// message.error(data.msg);

					return Promise.reject(data);
				}
				// * 成功请求（在页面上除非特殊情况，否则不用处理失败逻辑）.
				if (import.meta.env.MODE != "devprod") {
					console.log("接口"+config.url+"--------解密后返回结果----------")
					console.log(option)
				}
				return option;
			},
			async (error: AxiosError) => {
				const { response } = error;
				// NProgress.done();
				// tryHideFullScreenLoading();
				// 请求超时单独判断，请求超时没有 response
				if (error.message.indexOf("timeout") !== -1) message.error("请求超时，请稍后再试");
				// 根据响应的错误状态码，做不同的处理
				if (response) checkStatus(response.status);
				// 服务器结果都没有返回(可能服务器错误可能客户端断网) 断网处理:可以跳转到断网页面
				if (!window.navigator.onLine) window.location.hash = "/500";
				return Promise.reject(error);
			}
		);
	}

	// * 常用请求方法封装
	get<T>(url: string, params?: object, _object = {}): Promise<ResultData<T>> {
		return this.service.get(url, { params, ..._object });
	}
	post<T>(url: string, params?: object, _object = {}): Promise<ResultData<T>> {
		return this.service.post(url, params, _object);
	}
	put<T>(url: string, params?: object, _object = {}): Promise<ResultData<T>> {
		return this.service.put(url, params, _object);
	}
	delete<T>(url: string, params?: any, _object = {}): Promise<ResultData<T>> {
		return this.service.delete(url, { params, ..._object });
	}
}

export default new RequestHttp(config);
