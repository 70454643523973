import { Button, Col, Empty, Modal, Popconfirm, Progress, Row, message } from "antd";
import { useEffect, useState } from "react";
import {
	packagePage,
	productInfo,
	userPackageServicePurchase,
	userPackageServiceCashier,
	spacelist,
	userSpaceinfo,
	userSpacepurchase,
	userSpacecashier
} from "@/api/modules/business";
import { CheckOutlined, RightOutlined } from "@ant-design/icons";
import { store } from "@/redux";
import { useNavigate } from "react-router-dom";

import Macy from "macy";
import Cashier from "@/components/cashier";

interface Params1 {
	questionTitle: string;
	questionAnswer: string;
	themeId: string;
}
interface Params {
	questionInfoRespList: Array<Params1>;
}
const PackagePrice = (props: any) => {
	const { servieType } = props;
	const [list, setList]: any = useState<Params[]>([]);
	const [list2, setList2]: any = useState<Params[]>([]);
	const [option, setOption]: any = useState({});
	const [payoption, setPayOption]: any = useState({});
	const [token, setToken] = useState<string>("1");
	const [isModalOpen, setIsModalOpen] = useState(false);

	const [type, setType] = useState<string>("1");
	const [spaceinfo, setSpaceinfo]: any = useState({});
	const [orderCode, setOrderCode]: any = useState("");
	const [showCashier, setShowCashier]: any = useState(true);

	const navigate = useNavigate();

	useEffect(() => {
		setToken(store.getState().global.token);
		if (servieType == "1") getData();
		if (servieType == "2") {
			getData2();
			getuserSpaceinfo();
		}
		setType(servieType);
	}, []);

	const getData = async () => {
		const { data }: any = await packagePage({
			current: 0,
			name: "",
			size: 100,
			status: ""
		});
		if (!data) return;
		setList(data.records);

		setTimeout(() => {
			let masonry = new Macy({
				container: ".macy-container", // 图像列表容器
				trueOrder: false,
				waitForImages: false,
				useOwnImageLoader: false,
				debug: true,
				margin: { x: 0, y: 30 }, // 设计列与列的间距
				columns: 3 // 设置列数
			});
			console.log(masonry);
		});
	};
	const getData2 = async () => {
		const { data }: any = await spacelist({});
		if (!data) return;
		setList2(data);
	};

	const purchase = async o => {
		if (!token) {
			message.warning("帐户未登录，请先登录再购买！");
			navigate("/login");
		} else {
			let A: any = await userPackageServicePurchase({ packageServiceCode: o.packageServiceCode });
			if (A.code === 200) {
				let B: any = await userPackageServiceCashier({ orderCode: A.data.orderCode });
				if (!B.data) return;
				setPayOption(B.data);
				// getCode(A.data.orderCode, "1");
				setOrderCode(A.data.orderCode);
				setShowCashier(true);
				setIsModalOpen(true);
			}
		}
	};

	const purchase2 = async o => {
		if (!token) {
			message.warning("帐户未登录，请先登录再购买！");
			navigate("/login");
		} else {
			let A: any = await userSpacepurchase({ spaceServiceCode: o.spaceServiceCode });
			if (A.code === 200) {
				let B: any = await userSpacecashier({ orderCode: A.data.orderCode });
				if (!B.data) return;
				setPayOption(B.data);
				// getCode(A.data.orderCode, "1");
				setOrderCode(A.data.orderCode);
				setShowCashier(true);
				setIsModalOpen(true);
			}
		}
	};

	const handleCancel = () => {
		setShowCashier(false);
		setTimeout(() => {
			setIsModalOpen(false);
		});
	};
	const openp = async item => {
		const { data }: any = await productInfo({
			packageProductServiceCode: item.packageProductServiceCode
		});
		if (!data) return;
		console.log(data);
		if (data.wyUrl) data.wyUrl = data.wyUrl.replace(/,/g, "<br/>");
		setOption(data);
	};

	const changType = type => {
		setType(type);
		if (type == 1) {
			getData();
			setList2([]);
			setSpaceinfo({});
		}
		if (type == 2) {
			getData2();
			setList([]);
			if (store.getState().global.token) getuserSpaceinfo();
		}
	};

	const getuserSpaceinfo = async () => {
		const { data }: any = await userSpaceinfo({});
		if (!data) return;
		setSpaceinfo(data);
	};

	const goMyCloud = () => {
		navigate("/myCloud");
	};

	const closeModal = () => {
		setShowCashier(false);
		setTimeout(() => {
			setIsModalOpen(false);
		});
		if (type == "1") {
			getData();
		} else {
			getData2();
			getuserSpaceinfo();
		}
	};

	return (
		<>
			<div className="pricePage">
				<div className="pricePage-switch">
					<div className={type == "1" ? "active" : ""} onClick={() => changType(1)}>
						套餐服务
					</div>
					<div className={type == "2" ? "active" : ""} onClick={() => changType(2)}>
						空间服务
					</div>
				</div>
				{type == "1" ? (
					<>
						<div className="t">
							<div>套餐服务</div>
							<div>可根据不同需求选择相应套餐</div>
						</div>
						<div className="c">
							<div>
								<div className="macy-container">
									{list.map(item => (
										<div className="pricePage-div" key={item.id}>
											<div>
												<div className="title">{item.name}</div>
												<div className="remarks">{item.remarks}</div>
												<div className="priceAll">
													{item.price ? <div>￥{item.price / 100}</div> : ""}
													{item.original ? <div className="priceAll_2">￥{item.original / 100}</div> : ""}
													{item.purchaseLimit ? <div className="priceAll_3">限购次数：{item.purchaseLimit}</div> : ""}
												</div>
												{item.stock !== 0 ? (
													<Button type="primary" size="large" onClick={() => purchase(item)}>
														立即购买
													</Button>
												) : (
													<Button type="default" disabled size="large" onClick={() => purchase(item)}>
														已售馨
													</Button>
												)}
												<ul>
													<li>
														<div>● 产品服务</div>
														<div>
															<Popconfirm
																placement="topLeft"
																title={
																	<>
																		{option.productType == "1" ? "权益服务" : ""}
																		{option.productType == "2" ? "水印服务" : ""}
																		{option.productType == "3" ? "监测服务" : ""}
																		{option.productType == "4" ? "空间服务" : ""}
																	</>
																}
																description={
																	<>
																		{option.productType == "1" ? (
																			<div className="popHomeDiv">
																				<div>
																					<span>￥{option.price / 100}</span>
																					<span>/次</span>
																				</div>
																				<ul>
																					<li>
																						<div>存证方式</div>
																						<div>{option.serviceMode}</div>
																					</li>
																					<li>
																						<div>● 文件格式范围</div>
																						<div>{option.format}</div>
																					</li>
																					<li>
																						<div>● 文件存储时限</div>
																						{option.valid == "99" ? <span>永久</span> : <span>{option.valid}年</span>}
																					</li>
																				</ul>
																			</div>
																		) : (
																			""
																		)}
																		{option.productType == "2" ? (
																			<div className="popHomeDiv">
																				<div>
																					<span>￥{option.price / 100}</span>
																					<span>/次</span>
																				</div>
																				<ul>
																					<li>
																						<div>比对方式</div>
																						<div>{option.serviceMode}</div>
																					</li>
																					<li>
																						<div>● 文件格式范围</div>
																						<div>{option.format}</div>
																					</li>
																					<li>
																						<div>● 文件存储时限</div>
																						{option.valid == "99" ? <span>永久</span> : <span>{option.valid}年</span>}
																					</li>
																				</ul>
																			</div>
																		) : (
																			""
																		)}
																		{option.productType == "3" ? (
																			<div className="popHomeDiv">
																				<div>
																					<span>￥{option.price / 100}</span>
																					<span>天/次</span>
																				</div>
																				<ul>
																					<li>
																						<div>监测方式</div>
																						<div>{option.serviceMode}</div>
																					</li>
																					<li>
																						<div>● 监测站点</div>
																						<div dangerouslySetInnerHTML={{ __html: option.wyUrl }}></div>
																					</li>
																				</ul>
																			</div>
																		) : (
																			""
																		)}
																		{option.productType == "4" ? (
																			<div className="popHomeDiv">
																				<div>
																					<span>￥{option.price / 100}</span>
																					<span>/次</span>
																				</div>
																				<ul>
																					<li>
																						<div>● 空间服务大小</div>
																						<div>{option.size}MB</div>
																					</li>
																					<li>
																						<div>● 空间服务时限</div>
																						<div>{option.validity}个月</div>
																					</li>
																					<li>
																						<div>● 空间服务说明</div>
																						<div>{option.explains}</div>
																					</li>
																				</ul>
																			</div>
																		) : (
																			""
																		)}
																	</>
																}
																okText="知道了"
																cancelText="取消"
															>
																<a onClick={() => openp(item)}>{item.productServiceName}</a>
															</Popconfirm>

															<CheckOutlined />
														</div>
													</li>
													<li>
														<div>● 使用次数</div>
														<div>
															<span>{item.count}次</span>
															<CheckOutlined />
														</div>
													</li>
													<li>
														<div>● 有效期限</div>
														<div>
															<span>{item.valid}个月</span>
															<CheckOutlined />
														</div>
													</li>
													{item.subsidiary
														? item.subsidiary.map((opt, index) => (
																<li key={opt.packageProductServiceCode}>
																	<div>● 附属权益{index + 1}</div>
																	<div>
																		<span>
																			赠送
																			<Popconfirm
																				placement="topLeft"
																				title={
																					<>
																						{option.productType == "1" ? "权益服务" : ""}
																						{option.productType == "2" ? "水印服务" : ""}
																						{option.productType == "3" ? "监测服务" : ""}
																						{option.productType == "4" ? "空间服务" : ""}
																					</>
																				}
																				description={
																					<>
																						{option.productType == "1" ? (
																							<div className="popHomeDiv">
																								<div>
																									<span>￥{option.price / 100}</span>
																									<span>/次</span>
																								</div>
																								<ul>
																									<li>
																										<div>存证方式</div>
																										<div>{option.serviceMode}</div>
																									</li>
																									<li>
																										<div>● 文件格式范围</div>
																										<div>{option.format}</div>
																									</li>
																									<li>
																										<div>● 文件存储时限</div>
																										{option.valid == "99" ? <span>永久</span> : <span>{option.valid}年</span>}
																									</li>
																								</ul>
																							</div>
																						) : (
																							""
																						)}
																						{option.productType == "2" ? (
																							<div className="popHomeDiv">
																								<div>
																									<span>￥{option.price / 100}</span>
																									<span>/次</span>
																								</div>
																								<ul>
																									<li>
																										<div>比对方式</div>
																										<div>{option.serviceMode}</div>
																									</li>
																									<li>
																										<div>● 文件格式范围</div>
																										<div>{option.format}</div>
																									</li>
																									<li>
																										<div>● 文件存储时限</div>
																										{option.valid == "99" ? <span>永久</span> : <span>{option.valid}年</span>}
																									</li>
																								</ul>
																							</div>
																						) : (
																							""
																						)}
																						{option.productType == "3" ? (
																							<div className="popHomeDiv">
																								<div>
																									<span>￥{option.price / 100}</span>
																									<span>天/次</span>
																								</div>
																								<ul>
																									<li>
																										<div>监测方式</div>
																										<div>{option.serviceMode}</div>
																									</li>
																									<li>
																										<div>● 监测站点</div>
																										<div dangerouslySetInnerHTML={{ __html: option.wyUrl }}></div>
																									</li>
																								</ul>
																							</div>
																						) : (
																							""
																						)}
																						{option.productType == "4" ? (
																							<div className="popHomeDiv">
																								<div>
																									<span>￥{option.price / 100}</span>
																									<span>/次</span>
																								</div>
																								<ul>
																									<li>
																										<div>● 空间服务大小</div>
																										<div>{option.size}MB</div>
																									</li>
																									<li>
																										<div>● 空间服务时限</div>
																										<div>{option.validity}个月</div>
																									</li>
																									<li>
																										<div>● 空间服务说明</div>
																										<div>{option.explains}</div>
																									</li>
																								</ul>
																							</div>
																						) : (
																							""
																						)}
																					</>
																				}
																				okText="知道了"
																				cancelText="取消"
																			>
																				<a onClick={() => openp(opt)}>{opt.name}</a>
																			</Popconfirm>
																			{opt.count}次
																		</span>
																		<CheckOutlined />
																	</div>
																</li>
														  ))
														: ""}
												</ul>
											</div>
										</div>
									))}
								</div>
							</div>
							{list.length == 0 ? <Empty /> : ""}
						</div>
					</>
				) : (
					<>
						<div className="t">
							<div>空间容量</div>
							{store.getState().global.token ? (
								<div className="t-progress">
									<div>
										<div>
											已使用：{spaceinfo.useSize}MB / {spaceinfo.size}MB
										</div>
										<div onClick={goMyCloud}>
											查看详情
											<RightOutlined />
										</div>
									</div>
									<Progress percent={(spaceinfo.useSize / spaceinfo.size) * 100} showInfo={false} size="default" />
								</div>
							) : (
								""
							)}
							<div>可根据不同需求选择相应的储存空间</div>
						</div>
						<div className="c">
							<div>
								<Row>
									{list2.map(item => (
										<Col span={8} key={item.spaceServiceCode}>
											<div>
												<div className="title">{item.spaceServiceName}</div>
												<div className="remarks">{item.remarks}</div>
												<div className="priceAll">
													{item.price ? <div>￥{item.price / 100}</div> : ""}
													<div></div>
													{/* {item.original ? <div>￥{item.original / 100}</div> : ""} */}
												</div>
												<ul style={{ marginTop: "15px" }}>
													<li>
														<div>● 存储时限</div>
														<div>
															<span>{item.validity}个月</span>
															<CheckOutlined />
														</div>
													</li>
													<li>
														<div>● 存储大小</div>
														<div>
															<span>{item.size}MB</span>
															<CheckOutlined />
														</div>
													</li>
												</ul>
												{item.stock !== 0 ? (
													<Button type="primary" size="large" onClick={() => purchase2(item)}>
														立即购买
													</Button>
												) : (
													<Button type="default" disabled size="large" onClick={() => purchase(item)}>
														已售馨
													</Button>
												)}
												<div style={{ color: "#8C9094" }}>
													服务说明：空间服务购买后立即生效，生效后不支持扣款，具体详情请咨询客服。
												</div>
											</div>
										</Col>
									))}
								</Row>
							</div>
							{list2.length == 0 ? <Empty /> : ""}
						</div>
					</>
				)}
			</div>
			<Modal title="服务购买" maskClosable={false}  open={isModalOpen} onCancel={handleCancel} footer={null} width={480}>
				<div className="modalHomeDiv">
					<div className="t">
						<div>支付金额</div>
						<div>￥{payoption.orderAmount / 100}</div>
					</div>
					{showCashier ? <Cashier orderCode={orderCode} closeModal={closeModal} /> : ""}
				</div>
			</Modal>
		</>
	);
};

export default PackagePrice;
