import { Alert, Button, Card, Col, Empty, Input, Modal, Row, Select, SelectProps, message } from "antd";
import { useEffect, useState } from "react";
import { areacityList, areaprovinceList, intentadd, lawyerInsert, lawyerPage } from "@/api/modules/business";
import { RightOutlined, EnvironmentOutlined, PhoneOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { store } from "@/redux";
import ls from "@/assets/images/home/ls.png";
import * as _ from "lodash";
import { encryption } from "@/utils/pubilc/encryption";

const LegalSupport = () => {
	// const [list, setList]: any = useState<Params[]>([]);
	const [list, setList]: any = useState<[]>([]);
	const navigate = useNavigate();
	const [isModalOpen, setIsModalOpen] = useState(false);

	const serviceAreaArray = [
		{ value: "1", label: "知识产权" },
		{ value: "2", label: "著作权" },
		{ value: "3", label: "不正当竞争" }
	];

	const options: SelectProps["options"] = serviceAreaArray;

	const [lawyerName, setLawyerName] = useState<string>("");
	const [contactWay, setContactWay] = useState<string>("");
	const [lawyerNo, setLawyerNo] = useState<string>("");
	const [lawyerOffice, setLawyerOffice] = useState<string>("");
	const [region, setRegion] = useState<string>("");
	const [serviceArea, setServiceArea] = useState<string>("");
	const [errMsg, setErrMsg] = useState<string>("");
	const [selectvalue, setSelectvalue] = useState<string[]>([]);
	const [options1, setOptions1] = useState([]);
	const [value1, setValue1] = useState(null);
	const [options2, setOptions2] = useState([]);
	const [value2, setValue2] = useState(null);

	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		const { data }: any = await lawyerPage({ current: 0, size: 100, lawyerName: "", region: "", onlineStatus: "1" });
		if (!data) return;
		for (let i of data.records) {
			i.regionName = eval(i.regionName);
			i.serviceArea = servicearea(eval(i.serviceArea));
		}
		setList(data.records);
	};
	/*
	const getProvinceData = async () => {
		const optionsProvinceArray=[];
		const { data }: any = await province();
		for (let k of data) {
			let vk={ "value": k.areaId, "label": k.areaName };
			optionsProvinceArray.push(vk);
		}
		return 	optionsProvinceArray;
	};
	*/
	const servicearea = (params: string[]) => {
		let serviceAreaArr = [];
		params.forEach(param => {
			serviceAreaArray.forEach(item => {
				if (item.value === param) {
					serviceAreaArr.push(item.label);
				}
			});
		});
		return serviceAreaArr;
	};

	const open = () => {
		if (!store.getState().global.token) {
			message.warning("帐户未登录，请先登录！");
			navigate("/login");
		} else {
			setErrMsg("");
			setIsModalOpen(true);
			getShen();
			if (value1) handleChange1(value1);
		}
	};
	const getShen = async () => {
		let { data } = await areaprovinceList({});
		if (!data) return;
		setOptions1(
			_.map(data, x => {
				return {
					label: x.areaName,
					value: x.areaId
				};
			})
		);
	};

	const handleChange1 = async value => {
		setValue1(value);
		let { data } = await areacityList({ parentId: value });
		if (!data) return;
		setOptions2(
			_.map(data, x => {
				return {
					label: x.areaName,
					value: x.areaId
				};
			})
		);
	};
	const handleChange2 = async value => {
		setValue2(value);
		setRegion(JSON.stringify([value1, value]));
	};
	const handleChange = value => {
		setSelectvalue(value);
		setServiceArea(JSON.stringify(value));
	};
	const handleCancel = () => {
		setIsModalOpen(false);
	};
	const ok = async () => {
		setErrMsg("");
		let msg = "";
		if (!lawyerName) {
			msg = "姓名不能为空";
			setErrMsg(msg);
			return;
		}

		if (!contactWay) {
			msg = "手机号码不能为空";
			setErrMsg(msg);
			return;
		}

		let reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
		if (!reg_tel.test(contactWay)) {
			msg = "手机号格式不正确";
			setErrMsg(msg);
			return;
		}

		if (!lawyerNo) {
			msg = "律师证编号不能为空";
			setErrMsg(msg);
			return;
		}
		if (!lawyerOffice) {
			msg = "律所不能为空";
			setErrMsg(msg);
			return;
		}

		if (!region) {
			msg = "所在地区不能为空";
			setErrMsg(msg);
			return;
		}

		if (!serviceArea) {
			msg = "擅长领域不能为空";
			setErrMsg(msg);
			return;
		}
		let A: any = await lawyerInsert({
			contactWay: contactWay,
			lawyerName: lawyerName,
			lawyerNo: lawyerNo,
			lawyerOffice: lawyerOffice,
			region: region,
			serviceArea: serviceArea
		});
		if (A.code == 200) {
			message.success("提交成功");
			setIsModalOpen(false);
			setLawyerName("");
			setContactWay("");
			setLawyerNo("");
			setLawyerOffice("");
			setRegion("");
			setServiceArea("");
			setValue1(null);
			setValue2(null);
			setSelectvalue([]);
		}
	};
	const seeContactWay = async(item) => {
		if (!store.getState().global.token) {
			message.warning("帐户未登录，请先登录！");
			navigate("/login");
		} else {
			let arr: any = _.cloneDeep(list);
			for (let i of arr) {
				if (i.lawyerId == item.lawyerId) {
					i.isSee = true;
				}
			}
			setList(arr);
			let { data } = await intentadd({ lawyerId: item.lawyerId });
			if (!data) return;
		}
	};
	return (
		<>
			<div className="legalSupportpage">
				<div className="t">
					<div>律师咨询服务重磅上线，快来咨询！</div>
					<div>隐形水印+区块链存证+在线公证+侵权监测+水印比对+律师咨询，全链路保护数字作品。</div>
				</div>
				<div className="c">
					<div>
						<div className="title">入驻律师</div>
						<div className="title-2">答设免费提供律师咨询方式，诚邀知识产权律师入驻平台。</div>

						<Button type="primary" size="large" onClick={open}>
							点击申请
							<RightOutlined />
						</Button>

						<Row>
							{list.map(item => (
								<Col span={6} key={item.lawyerId}>
									<Card
										hoverable
										cover={item.headImage ? <img alt="example" src={item.imageUrl} /> : <img alt="example" src={ls} />}
									>
										<div className="cardiv">
											<div className="cardiv-1">{item.lawyerName}</div>
											<div className="cardiv-2">{item.lawyerOffice}</div>
											<div className="cardiv-4"></div>
											<div className="cardiv-3">
												<EnvironmentOutlined />
												{item.regionName}
											</div>
											<div className="cardiv-3">
												<PhoneOutlined />
												{item.isSee ? item.contactWay : encryption(item.contactWay)}
												{item.isSee ? (
													""
												) : (
													<span style={{ marginLeft: "15px", color: "#F26E21" }} onClick={() => seeContactWay(item)}>
														点击查看
													</span>
												)}
											</div>
											<div className="cardiv-5">
												{item.serviceArea.map(optin => (
													<span key={optin}>{optin}</span>
												))}
											</div>
										</div>
									</Card>
								</Col>
							))}
						</Row>
						{list.length == 0 ? <Empty /> : ""}
					</div>
				</div>
				<Modal title="联系我们" open={isModalOpen} onCancel={handleCancel} footer={null} width={500}>
					<div className="legalSupportpage-modal">
						<Alert
							message="平台将会严格保护你的个人信息，收到你的申请信息后会在1-3个工作日与你联系，请保持电话畅通。"
							type="warning"
							showIcon
							banner
						/>
						<div>
							<div>姓名</div>
							<Input placeholder="请输入姓名" value={lawyerName} onChange={e => setLawyerName(e.target.value)} />
						</div>
						<div>
							<div>手机号码</div>
							<Input
								placeholder="请输入手机号码"
								maxLength={11}
								value={contactWay}
								onChange={e => setContactWay(e.target.value)}
							/>
						</div>
						<div>
							<div>律师证编号</div>
							<Input placeholder="请输入律师证编号" value={lawyerNo} onChange={e => setLawyerNo(e.target.value)} />
						</div>
						<div>
							<div>律所</div>
							<Input placeholder="请输入律所" value={lawyerOffice} onChange={e => setLawyerOffice(e.target.value)} />
						</div>
						<div>
							<div>所在地区</div>
							<div className="ssq">
								<div>
									<span className="s">省</span>
									<Select
										value={value1}
										style={{ width: "100%" }}
										placeholder="点击选择省"
										onChange={handleChange1}
										options={options1}
									/>
								</div>
								<div>
									<span>市</span>
									<Select
										onChange={handleChange2}
										value={value2}
										style={{ width: "100%" }}
										placeholder="点击选择市"
										options={options2}
									/>
								</div>
							</div>
						</div>
						<div>
							<div>擅长领域</div>
							<Select
								mode="multiple"
								value={selectvalue}
								style={{ width: "100%" }}
								placeholder="请选择擅长领域"
								onChange={handleChange}
								options={options}
							/>
						</div>
						{errMsg ? <Alert type="error" message={errMsg} banner /> : ""}
						<div className="buttons">
							<Button block size="large" onClick={() => setIsModalOpen(false)}>
								取消
							</Button>
							<Button block size="large" type="primary" onClick={ok}>
								提交
							</Button>
						</div>
					</div>
				</Modal>
			</div>
		</>
	);
};

export default LegalSupport;
