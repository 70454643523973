/* 产引入jsencrypt实现数据RSA加密 */
import JSEncrypt from 'jsencrypt' // 处理长文本数据时报错 jsencrypt.js Message too long for RSA
/* 产引入encryptlong实现数据RSA加密 */
import Encrypt from 'encryptlong' // encryptlong是基于jsencrypt扩展的长文本分段加解密功能。

// 公钥key
const publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDC0q6fEhy+ozalMfO9djanrR7eagI63cB95/AVsc+GcpGk9TD4svsD4dVWWSiSlKydxISBg1exaK3v6NSYBdtUEUeTlLa+NePzCUieT91c50RhuKATsiUe7SZays0Qg3ciYZFdV/YXoPNcSI0UCaNfLn2gBYBYEFSJS1UPXBNFUwIDAQAB'
const privateKey = 'MIICdwIBADANBgkqhkiG9w0BAQEFAASCAmEwggJdAgEAAoGBANGcHFrKvKD65PjNa1IiywpKiEBozw9RWyFPIe3YxFXhh6SC808oC04KQsiupUI2a++1FHZ3Bs49EI6dJXK8fXpnBfoVwdzWCUIzz3u+92OKg3MWCNdom2RNjs8tYVe61nKHqvX1TRA4EDOzcV4ZIrG89Qj09Vz9FAuwTD4O6pf7AgMBAAECgYBDS9yqpaaUBJlY7BLFxxfrEOzXyQ6Ze2RHuHFLPKokbIkxHz3grxmaD59LcQ7ryC81Qaz1L9fGhUJ4lyl7wdqCyXRBnd4UPbSWaPhxwYr3sR/h2NnaH4SH18ZPE7gbNLAHtMmwPq7F8+odV638f6CPGnwiJDKmEtH1MHnv1Rq2qQJBAP+OzX9vOTP4BkfU9VoESYQacKo//7DM832cFWK2Q2sieGDDG/promZc9UHHLKG1XnSTpCDbLlgRRdtZZIV23j0CQQDR+PSq3SsNIvWm9WTZ0HjphPgLyvKjcYsZ0phhqtAFpC4R0OFcVfdeI8uOX9DeEJgGMMrXq2/5YvyyzqmDraqXAkB+1VLsIjlTr47+a5Txfhs2cyDjfivahX17I9IBUdAYBd2UuIDUQkMxZoVahg2CnE8qsHPO0rkngDsZaD5ZdutJAkEAz6ci6LuzsNkrEgPLyjGG8oKDAL6juYo348DG3IgeFAYrXYky5qtJ6TsDO3l8ybCleDHW2PV91qfTe04wjL46XwJBAPyf38LuUfs2bzjHa3wjgs2TOmqPxNxJioZlvo8+Kk/p3nb6ZcvaiqYOEA+sHuG7+f70BaOj28zPyxta6sVCDfs='

/* JSEncrypt加密 */
export const rsaPublicData = (data) => {
    let jsencrypt = new JSEncrypt()
    jsencrypt.setPublicKey(publicKey)
    // 如果是对象/数组的话，需要先JSON.stringify转换成字符串
    let result = jsencrypt.encrypt(data)
    return result
}
/* JSEncrypt解密 */
export const rsaPrivateData = (data) => {
    let jsencrypt = new JSEncrypt()
    jsencrypt.setPrivateKey(privateKey)
    // 如果是对象/数组的话，需要先JSON.stringify转换成字符串
    let result = jsencrypt.decrypt(data)
    return result
}
/* 加密 */
export const rsaEncrypt = (data) => {
    const PUBLIC_KEY = publicKey
    let encryptor = new Encrypt()
    encryptor.setPublicKey(PUBLIC_KEY)
    // 如果是对象/数组的话，需要先JSON.stringify转换成字符串
    const result = encryptor.encryptLong(data)
    return result
}
/* 解密 - PRIVATE_KEY - 验证 */
export const rsaDecrypt = (data) => {
    const PRIVATE_KEY = privateKey
    let encryptor = new Encrypt()
    encryptor.setPrivateKey(PRIVATE_KEY)
    // 如果是对象/数组的话，需要先JSON.stringify转换成字符串
    let result = encryptor.decryptLong(data)
    return result
}

