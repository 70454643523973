import { useRef } from "react";
import { Modal, Dropdown, message, Space, MenuProps } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { setToken } from "@/redux/modules/global/action";
import PasswordModal from "./PasswordModal";
import InfoModal from "./InfoModal";
// import avatar from "@/assets/images/avatar.png";
import { CaretDownOutlined } from "@ant-design/icons";
import { updateCollapse } from "@/redux/modules/menu/action";
import { HOME_URL } from "@/config/config";
import { store } from "@/redux";
import { IconFont } from "@/assets/iconfont";

const AvatarIcon = (props: any) => {
	const { setToken } = props;
	const navigate = useNavigate();

	interface ModalProps {
		showModal: (params: { name: number }) => void;
	}
	const passRef = useRef<ModalProps>(null);
	const infoRef = useRef<ModalProps>(null);

	const items: MenuProps["items"] = [
		{
			label: "账户管理",
			key: "0",
			onClick: () => handleMenuClick(1)
		},
		{
			label: "返回首页",
			key: "2",
			onClick: () => handleMenuClick(3)
		},
		{
			label: "退出登录",
			key: "1",
			onClick: () => handleMenuClick(2)
		}
	];

	const handleMenuClick = (num: number) => {
		if (num == 1) {
			navigate("/account");
		} else if (num == 2) {
			logout();
		} else if (num == 3) {
			navigate(HOME_URL);
		}
	};

	// 退出登录
	const logout = () => {
		Modal.confirm({
			title: "温馨提示",
			icon: <ExclamationCircleOutlined />,
			content: "是否确认退出登录？",
			okText: "确认",
			cancelText: "取消",
			onOk: () => {
				setToken("");
				message.success("退出登录成功！");
				navigate(HOME_URL);
			}
		});
	};

	return (
		<>
			{/* <Avatar size="large" src={avatar} /> */}
			{/* <UserOutlined className="userOutlinedIcon" style={{ marginRight: "5px", color: "#F26E21" }} /> */}
			<IconFont type="icon-gerenfill-copy" />
			<Dropdown menu={{ items }} trigger={["click"]}>
				<a onClick={e => e.preventDefault()}>
					{store.getState().global.user ? store.getState().global.user.userName : ""}
					<Space>
						<CaretDownOutlined />
					</Space>
				</a>
			</Dropdown>

			<InfoModal innerRef={infoRef}></InfoModal>
			<PasswordModal innerRef={passRef}></PasswordModal>
		</>
	);
};

const mapStateToProps = (state: any) => state.menu;
const mapDispatchToProps = { setToken, updateCollapse };
export default connect(mapStateToProps, mapDispatchToProps)(AvatarIcon);
