// import { Login } from "@/api/interface/index";
import http from "@/api";
import { apiUrl } from "@/config/config";

/**
 * @name 登录模块
 */
// * 用户登录接口
// export const loginApi = (params: Login.ReqLoginForm) => {
// 	return http.post<Login.ResLogin>(PORT1 + `/login`, params);
// 	return http.post<Login.ResLogin>(PORT1 + `/login`, {}, { params }); // post 请求携带 query 参数  ==>  ?username=admin&password=123456
// 	return http.post<Login.ResLogin>(PORT1 + `/login`, qs.stringify(params)); // post 请求携带 表单 参数  ==>  application/x-www-form-urlencoded
// 	return http.post<Login.ResLogin>(PORT1 + `/login`, params, { headers: { noLoading: true } }); // 控制当前请求不显示 loading
// };

// // * 获取按钮权限
// export const getAuthorButtons = () => {
// 	return http.get<Login.ResAuthButtons>(PORT1 + `/auth/buttons`);
// };

// * 获取菜单列表
// export const getMenuList = () => {
// 	return http.get<Menu.MenuOptions[]>(PORT1 + `/menu/list`);
// };

export const phoneCheck = (params: any) => {
	return http.post<Menu.MenuOptions[]>(apiUrl + `/user/phoneCheck`, params);
};

export const sendSMS = (params: any) => {
	return http.post<Menu.MenuOptions[]>(apiUrl + `/v1/sendSMS`, params);
};

export const login = (params: any) => {
	return http.post<Menu.MenuOptions[]>(apiUrl + `/user/register`, params);
};

export const userDetail = (params: any) => {
	return http.post<Menu.MenuOptions[]>(apiUrl + `/user/detail`, params);
};

export const payQrCode = (params: any) => {
	return http.post<Menu.MenuOptions[]>(apiUrl + `/order/pay/qrCode`, params);
};

export const toPay = (params: any) => {
	return http.post(apiUrl + `/order/pay/toPay`, params);
};

export const agreementQuery = (params: any) => {
	return http.post(apiUrl + `/agreement/query`, params);
};

export const agreementPreInfo = (params: any) => {
	return http.post(apiUrl + `/agreement/info`, params);
};

export const agreementApply = (params: any) => {
	return http.post(apiUrl + `/agreement/apply`, params);
};

export const agreementSign = (params: any) => {
	return http.post(apiUrl + `/agreement/sign`, params);
};

export const payQuery = (params: any) => {
	return http.post<Menu.MenuOptions[]>(apiUrl + `/order/pay/query`, params, { headers: { noLoading: true } });
};

export const enterpriseInfoAuthLogo = (params: any) => {
	return http.post<Menu.MenuOptions[]>(apiUrl + `/enterpriseInfo/authLogo`, params);
};

export const enterpriseInfoDecode = (params: any) => {
	return http.post<Menu.MenuOptions[]>(apiUrl + `/enterpriseInfo/decode`, params);
};

export const enterpriseInfoResult = (params: any) => {
	return http.post<Menu.MenuOptions[]>(apiUrl + `/enterpriseInfo/result`, params, { headers: { noLoading: true } });
};

export const enterpriseInfoSave = (params: any) => {
	return http.post<Menu.MenuOptions[]>(apiUrl + `/enterpriseInfo/save`, params);
};

export const userPasswordLogin = (params: any) => {
	return http.post<Menu.MenuOptions[]>(apiUrl + `/user/passwordLogin`, params);
};

export const userPhoneLogin = (params: any) => {
	return http.post<Menu.MenuOptions[]>(apiUrl + `/user/phoneLogin`, params);
};
export const userUpdatePassword = (params: any) => {
	return http.post<Menu.MenuOptions[]>(apiUrl + `/v1/updatePassword`, params);
};
