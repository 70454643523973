import { Layout } from "antd";
import AvatarIcon from "./components/AvatarIcon";
// import CollapseIcon from "./components/CollapseIcon";
// import BreadcrumbNav from "./components/BreadcrumbNav";
// import AssemblySize from "./components/AssemblySize";
// import Language from "./components/Language";
// import Theme from "./components/Theme";
// import Fullscreen from "./components/Fullscreen";
import "./index.less";
import logo from "@/assets/images/logo.png";
import { HOME_URL } from "@/config/config";
import { useNavigate } from "react-router-dom";

const LayoutHeader = () => {
	const { Header } = Layout;
	const navigate = useNavigate();
	const goHome =()=>{
		navigate(HOME_URL);
	}	
	return (
		<Header>
			<div className="header-lf">
				{/* <CollapseIcon />
				<BreadcrumbNav /> */}
				<div onClick={goHome}>
					<img className="login-icon" src={logo} alt="logo" />
					<span className="logo-text">答设版权保护平台</span>
				</div>
			</div>
			<div className="header-ri">
				{/* <AssemblySize />
				<Language />
				<Theme />
				<Fullscreen />
				<span className="username">Hooks</span> */}
				<AvatarIcon />
			</div>
		</Header>
	);
};

export default LayoutHeader;
