import React from "react";
import lazyLoad from "@/routers/utils/lazyLoad";
import { LayoutIndex } from "@/routers/constant";
import { RouteObject } from "@/routers/interface";

// 超级表格模块
const BusinessRouter: Array<RouteObject> = [
	{
		element: <LayoutIndex />,
		meta: {
			title: "业务模块"
		},
		children: [
			{
				path: "/transactions",
				element: lazyLoad(React.lazy(() => import("@/views/business/transactions/index"))),
				meta: {
					requiresAuth: true,
					title: "我的账单",
					key: "transactions"
				}
			},
			{
				path: "/obtainEvidence",
				element: lazyLoad(React.lazy(() => import("@/views/business/obtainEvidence/index"))),
				meta: {
					requiresAuth: true,
					title: "水印比对",
					key: "obtainEvidence"
				}
			},
			{
				path: "/existingEvidence",
				element: lazyLoad(React.lazy(() => import("@/views/business/existingEvidence/index"))),
				meta: {
					requiresAuth: true,
					title: "权益存证",
					key: "existingEvidence"
				}
			},
			{
				path: "/fullDetection",
				element: lazyLoad(React.lazy(() => import("@/views/business/fullDetection/index"))),
				meta: {
					requiresAuth: true,
					title: "侵权监测",
					key: "fullDetection"
				}
			},
			{
				path: "/myCloud",
				element: lazyLoad(React.lazy(() => import("@/views/business/myCloud/index"))),
				meta: {
					requiresAuth: true,
					title: "我的云盘",
					key: "myCloud"
				}
			},
			{
				path: "/myPackage",
				element: lazyLoad(React.lazy(() => import("@/views/business/myPackage/index"))),
				meta: {
					requiresAuth: true,
					title: "我的套餐",
					key: "myPackage"
				}
			},
			{
				path: "/account",
				element: lazyLoad(React.lazy(() => import("@/views/business/account/index"))),
				meta: {
					title: "帐户管理",
					key: "account"
				}
			}
		]
	}
];

export default BusinessRouter;
