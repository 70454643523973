import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Menu, Tour } from "antd";
import { searchRoute } from "@/utils/util";
// import { setMenuList } from "@/redux/modules/menu/action";
// import { setBreadcrumbList } from "@/redux/modules/breadcrumb/action";
// import { setAuthRouter } from "@/redux/modules/auth/action";
// import { getMenuList } from "@/api/modules/login";
import { connect } from "react-redux";
import type { MenuProps } from "antd";
// import * as Icons from "@ant-design/icons";
// import Logo from "./components/Logo";
import "./index.less";
import type { TourProps } from "antd";
import {
	SaveOutlined,
	PrinterOutlined,
	UserSwitchOutlined,
	DollarOutlined,
	CreditCardOutlined,
	SafetyOutlined,
} from "@ant-design/icons";
import { store } from "@/redux";
import { setUser } from "@/redux/modules/global/action";
import { IconFont } from "@/assets/iconfont";

// const data: any = [
// 	{ icon: "SaveOutlined", title: "权益存证", path: "/existingEvidence" },
// 	{ icon: "PrinterOutlined", title: "水印比对", path: "/obtainEvidence" },
// 	{ icon: "UserSwitchOutlined", title: "账户管理", path: "/account" },
// 	{ icon: "DollarOutlined", title: "我的套餐", path: "/" },
// 	{ icon: "CreditCardOutlined", title: "我的账单", path: "/transactions" }
// ];

const LayoutMenu = (props: any) => {
	const { setUser } = props;
	const { pathname } = useLocation();
	// const { isCollapse, setBreadcrumbList, setAuthRouter, setMenuList: setMenuListAction } = props;
	const [selectedKeys, setSelectedKeys] = useState<string[]>([pathname]);
	// const [openKeys, setOpenKeys] = useState<string[]>([]);
	const [open, setOpen] = useState<boolean>(false);

	// // 刷新页面菜单保持高亮
	// useEffect(() => {
	// 	setSelectedKeys([pathname]);
	// 	isCollapse ? null : setOpenKeys(getOpenKeys(pathname));
	// }, [pathname, isCollapse]);

	// // 设置当前展开的 subMenu
	// const onOpenChange = (openKeys: string[]) => {
	// 	if (openKeys.length === 0 || openKeys.length === 1) return setOpenKeys(openKeys);
	// 	const latestOpenKey = openKeys[openKeys.length - 1];
	// 	if (latestOpenKey.includes(openKeys[0])) return setOpenKeys(openKeys);
	// 	setOpenKeys([latestOpenKey]);
	// };

	// // 定义 menu 类型
	// type MenuItem = Required<MenuProps>["items"][number];
	// const getItem = (
	// 	label: React.ReactNode,
	// 	key?: React.Key | null,
	// 	icon?: React.ReactNode,
	// 	children?: MenuItem[],
	// 	type?: "group"
	// ): MenuItem => {
	// 	return {
	// 		key,
	// 		icon,
	// 		children,
	// 		label,
	// 		type
	// 	} as MenuItem;
	// };

	// // 动态渲染 Icon 图标
	// const customIcons: { [key: string]: any } = Icons;
	// const addIcon = (name: string) => {
	// 	return React.createElement(customIcons[name]);
	// };

	// // 处理后台返回菜单 key 值为 antd 菜单需要的 key 值
	// const deepLoopFloat = (menuList: Menu.MenuOptions[], newArr: MenuItem[] = []) => {
	// 	menuList.forEach((item: Menu.MenuOptions) => {
	// 		// 下面判断代码解释 *** !item?.children?.length   ==>   (!item.children || item.children.length === 0)
	// 		if (!item?.children?.length) return newArr.push(getItem(item.title, item.path, addIcon(item.icon!)));
	// 		newArr.push(getItem(item.title, item.path, addIcon(item.icon!), deepLoopFloat(item.children)));
	// 	});
	// 	return newArr;
	// };

	// 获取菜单列表并处理成 antd menu 需要的格式
	// const [menuList, setMenuList] = useState<MenuItem[]>([]);
	// const [loading, setLoading] = useState(false);
	// const getMenuData = async () => {
	// 	setLoading(true);
	// 	try {
	// 		// const { data } = await getMenuList();
	// 		// console.log(data);

	// 		if (!data) return;
	// 		setMenuList(deepLoopFloat(data));
	// 		// 存储处理过后的所有面包屑导航栏到 redux 中
	// 		setBreadcrumbList(findAllBreadcrumb(data));
	// 		// 把路由菜单处理成一维数组，存储到 redux 中，做菜单权限判断
	// 		const dynamicRouter = handleRouter(data);
	// 		setAuthRouter(dynamicRouter);
	// 		setMenuListAction(data);
	// 	} finally {
	// 		setLoading(false);
	// 	}
	// };
	// useEffect(() => {
	// 	getMenuData();
	// }, []);

	useEffect(() => {
		setSelectedKeys([pathname]);
	}, [pathname]);

	useEffect(() => {
		if (store.getState().global.user && store.getState().global.user.isGuide !== "0") setOpen(true);
	}, []);
	// const change = (current: number) => {
	// 	console.log(current);
	// };

	// 点击当前菜单跳转页面
	const navigate = useNavigate();
	const clickMenu: MenuProps["onClick"] = ({ key }: { key: string }) => {
		const route = searchRoute(key, props.menuList);
		if (route.isLink) window.open(route.isLink, "_blank");
		navigate(key);
	};
	const ref1 = useRef(null);
	const ref2 = useRef(null);
	const ref3 = useRef(null);
	const ref4 = useRef(null);
	const ref5 = useRef(null);
	const ref6 = useRef(null);
	const ref7 = useRef(null);
	const steps: TourProps["steps"] = [
		{
			title: "权益存证",
			description: "多种存证方式灵活选择，证明创作在先不再难",
			placement: "rightTop",
			target: () => ref1.current!
		},
		{
			title: "侵权监测",
			description: "24小时侵权监测侵权信息，侵权行为一览无余",
			placement: "rightTop",
			target: () => ref2.current!
		},
		{
			title: "水印比对",
			description: "全球领先的华科大水印溯源，留存侵权证据不再难",
			placement: "rightTop",
			target: () => ref3.current!
		},
		{
			title: "账户管理",
			description: "在这里，您可以修改自己的昵称，实名认证自己的身份",
			placement: "rightTop",
			target: () => ref4.current!
		},
		{
			title: "我的云盘",
			description: "在这里，你可以查看你的存储空间，清楚了解余量，及时补充云盘容量。",
			placement: "rightTop",
			target: () => ref5.current!
		},
		{
			title: "我的套餐",
			description: "在这里，您可以查看自己购买的套餐，套餐服务省时又省心",
			placement: "rightTop",
			target: () => ref6.current!
		},
		{
			title: "我的账单",
			description: "每笔交易都清晰可查，每笔费用都物有所值",
			placement: "rightTop",
			target: () => ref7.current!
		}
	];

	const change = current => {
		let top = current * 44 + 83 + "px";
		document.querySelector(".ant-tour").setAttribute("style", `top:${top}`);
	};
	const finish = () => {
		let obj: any = { ...store.getState().global.user };
		obj.isGuide = "0";
		setUser(obj);
	};
	return (
		<>
			<div className="menu">
				{/* <Spin spinning={loading} tip="Loading..."> */}
				{/* <Logo></Logo> */}
				<Menu
					// theme="dark"
					// mode="inline"
					triggerSubMenuAction="click"
					// openKeys={openKeys}
					selectedKeys={selectedKeys}
					onClick={clickMenu}
					// onOpenChange={onOpenChange}
				>
					<Menu.Item key={"/existingEvidence"}>
						<div ref={ref1}>
							<SaveOutlined />
							权益存证
						</div>
					</Menu.Item>
					<Menu.Item key={"/fullDetection"}>
						<div ref={ref2}>
							<SafetyOutlined />
							侵权监测
						</div>
					</Menu.Item>
					<Menu.Item key={"/obtainEvidence"}>
						<div ref={ref3}>
							<PrinterOutlined />
							水印比对
						</div>
					</Menu.Item>
					<Menu.Item key={"/account"}>
						<div ref={ref4}>
							<UserSwitchOutlined />
							账户管理
						</div>
					</Menu.Item>
					<Menu.Item key={"/myCloud"}>
						<div ref={ref5}>
							<IconFont type="icon-qiyeyunpan-copy" />
							我的云盘
						</div>
					</Menu.Item>
					<Menu.Item key={"/myPackage"}>
						<div ref={ref6}>
							<DollarOutlined />
							我的套餐
						</div>
					</Menu.Item>
					<Menu.Item key={"/transactions"}>
						<div ref={ref7}>
							<CreditCardOutlined />
							我的账单
						</div>
					</Menu.Item>
				</Menu>
				{/* </Spin> */}
			</div>
			<Tour
				open={open}
				onClose={() => setOpen(false)}
				steps={steps}
				onChange={(current: number) => change(current)}
				onFinish={finish}
			/>
		</>
	);
};

// const mapStateToProps = (state: any) => state.menu;
// const mapDispatchToProps = { setMenuList, setBreadcrumbList, setAuthRouter };
// export default connect(mapStateToProps, mapDispatchToProps)(LayoutMenu);

const mapDispatchToProps = { setUser };
export default connect(null, mapDispatchToProps)(LayoutMenu);
