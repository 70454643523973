import CryptoJS from 'crypto-js'

// 随机生成指定数量的16进制key
export const generatekey = () => {
    const library = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let key = ''
    for (let i = 0; i < 16; i++) {
        const randomPoz = Math.floor(Math.random() * library.length)
        key += library.substring(randomPoz, randomPoz + 1)
    }
    return key
}
// 加密
export const aesEncrypt = (word, keyStr) => {
    keyStr = keyStr || 'OeJ1iyRnFQ54g0ex' // 判断是否存在ksy，不存在就用定义好的key
    if (typeof (word) !== 'string') {
        word = JSON.stringify(word);
    }
    let key = CryptoJS.enc.Utf8.parse(keyStr)
    let srcs = CryptoJS.enc.Utf8.parse(word)
    let encrypted = CryptoJS.AES.encrypt(srcs, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 })
    return encrypted.toString()
}
// 解密
export const aesDecrypt = (word, keyStr) => {
    keyStr = keyStr || 'OeJ1iyRnFQ54g0ex'
    let key = CryptoJS.enc.Utf8.parse(keyStr)
    let decrypt = CryptoJS.AES.decrypt(word, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 })
    return CryptoJS.enc.Utf8.stringify(decrypt).toString()
}

export const toFormdata = (value) => {
    if (value) {
        let formData = new FormData()
        Object.keys(value).forEach(key => {
            const item = value[key]
            if (item) {
                formData.append(key, JSON.stringify(item))
            }
            else if (Array.isArray(item)) {
                if (item[0] && item[0] instanceof File) {
                    item.forEach(itemValue => {
                        formData.append(key, itemValue)
                    })
                } else {
                    formData.append(key, JSON.stringify(item))
                }
            }
            else if (item === undefined) {
                // Do nothing
            } else {
                formData.append(key, item)
            }
        })
        return formData
    } else {
        return
    }
}

