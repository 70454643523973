import logo_comp from "@/assets/images/home/logo.png";
import logo from "@/assets/images/logo.png";
import home_111 from "@/assets/images/home/home.png";
import { Button, Card, Col, message, Row } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { IconFont } from "@/assets/iconfont";
import { useEffect, useState } from "react";
import { store } from "@/redux/index";
import { useNavigate } from "react-router-dom";
// import QRcode from "@/assets/images/home/QRcode.jpg";
import wx from "@/assets/images/home/u1.png";
import { usercheckLogin } from "@/api/modules/business";

const Index = (props: any) => {
	const { emptytoken } = props;
	const { Meta } = Card;
	const [isAdd1, setIsAdd1] = useState<boolean>(false);
	const [isAdd2, setIsAdd2] = useState<boolean>(false);
	const [isAdd3, setIsAdd3] = useState<boolean>(false);
	const [isAdd4, setIsAdd4] = useState<boolean>(false);
	const [isAdd5, setIsAdd5] = useState<boolean>(false);
	const [isAdd6, setIsAdd6] = useState<boolean>(false);
	const [isAdd7, setIsAdd7] = useState<boolean>(false);
	// const [isShow, setIsShow] = useState<boolean>(false);
	const navigate = useNavigate();
	let aUrl = "http://www.dctechc.com/";
	let target = "_blank";

	useEffect(() => {
		// 滚动条滚动时触发
		window.addEventListener("scroll", scrollChange, true);
		scrollChange();
		return () => {
			window.removeEventListener("scroll", scrollChange, false);
		};
	}, []);
	const scrollChange = () => {
		// 监听滚动条距离顶部距离
		if (document.documentElement.scrollTop >= 0) setIsAdd1(true);
		if (document.documentElement.scrollTop >= 500) setIsAdd2(true);
		if (document.documentElement.scrollTop >= 1200) setIsAdd3(true);
		if (document.documentElement.scrollTop >= 1800) setIsAdd4(true);
		if (document.documentElement.scrollTop >= 2600) setIsAdd5(true);
		if (document.documentElement.scrollTop >= 3400) setIsAdd6(true);
		if (document.documentElement.scrollTop >= 4200) setIsAdd7(true);
	};
	const goto = async (url?: string) => {
		if (!url) return message.warning("敬请期待！");
		if (!store.getState().global.token) {
			message.warning("帐户未登录，请先登录！");
			navigate("/login");
		} else {
			let A: any = await usercheckLogin();
			console.log(A);
			if (A.code == 200) {
				navigate(url);
			} else {
				navigate("/login");
				emptytoken();
			}
		}
	};

	return (
		<div>
			<div className={isAdd1 ? "c-1 noMargin" : "c-1"}>
				<div>
					<Row>
						<Col span={12}>
							<div className="c">
								<div>数字作品全生命周期的保护</div>
								<div className="c-c">
									答设版权保护平台，依托华科大隐形水印、区块链等核心技术，为数字作品生产者、数字作品消费者、数字作品保护平台，提供一站式线上版权保护解决方案
								</div>
								<div>
									<div>
										<Button type="primary" size="large" onClick={() => goto("/existingEvidence")}>
											区块链存证
											<RightOutlined />
										</Button>
										<Button type="primary" size="large" onClick={() => goto("/existingEvidence")}>
											隐形水印
											<RightOutlined />
										</Button>
									</div>
									<div>
										<Button type="primary" size="large" onClick={() => goto("/existingEvidence")}>
											在线公证
											<RightOutlined />
										</Button>
										<Button size="large" onClick={() => goto("")}>
											版权登记
											<RightOutlined />
										</Button>
									</div>
								</div>
							</div>
						</Col>
						<Col span={12}>
							<div className="r">
								<img className="login-icon" src={home_111} />
								{/* <img className="login-icon" src={logo} alt="logo" /> */}
							</div>
						</Col>
					</Row>
				</div>
			</div>
			<div className={isAdd2 ? "c-2 noMargin" : "c-2"}>
				<div>
					<div className="t-1">区块链存证</div>
					<div className="t-2">权利人信息，作品信息、时间戳安全上链，发布即存证，证明创作在先不再难</div>
					<Row>
						<Col span={8}>
							<Card hoverable cover={<IconFont type="icon-shimingrenzheng-copy" />}>
								<Meta title="实名认证" />
							</Card>
						</Col>
						<Col span={8}>
							<Card hoverable cover={<IconFont type="icon-wj-wjsc" />}>
								<Meta title="上传作品" />
							</Card>
						</Col>
						<Col span={8}>
							<Card hoverable cover={<IconFont type="icon-zhangdan" />}>
								<Meta title="支付费用" />
							</Card>
						</Col>
					</Row>
					<Row>
						<Col span={8}>
							<Card hoverable cover={<IconFont type="icon-link" />}>
								<Meta title="作品上链" />
							</Card>
						</Col>
						<Col span={8}>
							<Card hoverable cover={<IconFont type="icon-zhengshu-copy-copy" />}>
								<Meta title="电子数据区块链存证证书查看" />
							</Card>
						</Col>
					</Row>
					<Button type="primary" size="large" onClick={() => goto("/existingEvidence")}>
						立即体验
						<RightOutlined />
					</Button>
				</div>
			</div>
			<div className={isAdd3 ? "c-2 nobackground noMargin" : "c-2 nobackground"}>
				<div>
					<div className="t-1">隐形水印</div>
					<div className="t-2">全球领先的华科大隐形水印技术，水印嵌入速度快、实时性好，溯源精度99%以上，溯源率高达90%</div>
					<Row>
						<Col span={8}>
							<Card hoverable cover={<IconFont type="icon-shimingrenzheng-copy" />}>
								<Meta title="实名认证" />
							</Card>
						</Col>
						<Col span={8}>
							<Card hoverable cover={<IconFont type="icon-wj-wjsc" />}>
								<Meta title="上传作品" />
							</Card>
						</Col>
						<Col span={8}>
							<Card hoverable cover={<IconFont type="icon-zhangdan" />}>
								<Meta title="支付费用" />
							</Card>
						</Col>
					</Row>
					<Row>
						<Col span={8}>
							<Card hoverable cover={<IconFont type="icon-shuiyin" />}>
								<Meta title="水印加载" />
							</Card>
						</Col>
						<Col span={8}>
							<Card hoverable cover={<IconFont type="icon-zhengshu-copy-copy" />}>
								<Meta title="华科大隐形水印存证证书查看" />
							</Card>
						</Col>
					</Row>
					<Button type="primary" size="large" onClick={() => goto("/existingEvidence")}>
						立即体验
						<RightOutlined />
					</Button>
				</div>
			</div>
			<div className={isAdd4 ? "c-2 noMargin bg-1" : "c-2 bg-1"}>
				{/* <div>
					<div>
						<svg
							width="2560px"
							height="100px"
							xmlns="http://www.w3.org/2000/svg"
							preserveAspectRatio="none"
							x="0px"
							y="0px"
							viewBox="0 0 2560 100"
						>
							<polygon points="2560 0 2560 100 0 100"></polygon>
						</svg>
					</div>
					<div></div>
				</div> */}
				<div className="c">
					<div className="t-1">版权登记</div>
					<div className="t-2">作品著作权自助登记，有效降低成本，提高效率，机制透明，保护原创者利益</div>
					<Row>
						<Col span={8}>
							<Card hoverable>
								<Meta avatar={<IconFont type="icon-icon-copy" />} title="文本" />
							</Card>
						</Col>
						<Col span={8}>
							<Card hoverable>
								<Meta avatar={<IconFont type="icon-xingzhuang-tupian" />} title="图片" />
							</Card>
						</Col>
						<Col span={8}>
							<Card hoverable>
								<Meta avatar={<IconFont type="icon-yinpin-copy" />} title="音频" />
							</Card>
						</Col>
					</Row>
					<Row>
						<Col span={8}>
							<Card hoverable>
								<Meta avatar={<IconFont type="icon-shipinbofang-copy" />} title="视频" />
							</Card>
						</Col>
						<Col span={8}>
							<Card hoverable>
								<Meta avatar={<img className="icon" src={logo} alt="logo" />} title="logo" />
							</Card>
						</Col>
						<Col span={8}>
							<Card hoverable>
								<Meta avatar={<IconFont type="icon-qita-copy" />} title="其他" />
							</Card>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<div className="bz">其他类型：字体、平面、网页、口述、戏曲、曲艺、舞蹈、模型等</div>
						</Col>
					</Row>

					<Button size="large" onClick={() => goto()}>
						立即体验
						<RightOutlined />
					</Button>
				</div>
			</div>
			<div className={isAdd5 ? "c-2 nobackground noMargin" : "c-2 nobackground"}>
				<div>
					<div className="t-1">在线公证</div>
					<div className="t-2">国家公证处在线公证，法律公信力加持，保障数字作品的真实性和合法性</div>
					<Row>
						<Col span={8}>
							<Card hoverable cover={<IconFont type="icon-shimingrenzheng-copy" />}>
								<Meta title="实名认证" />
							</Card>
						</Col>
						<Col span={8}>
							<Card hoverable cover={<IconFont type="icon-wj-wjsc" />}>
								<Meta title="上传作品" />
							</Card>
						</Col>
						<Col span={8}>
							<Card hoverable cover={<IconFont type="icon-zhangdan" />}>
								<Meta title="支付费用" />
							</Card>
						</Col>
					</Row>
					<Row>
						<Col span={8}>
							<Card hoverable cover={<IconFont type="icon-link" />}>
								<Meta title="在线公证&文件HASH上链" />
							</Card>
						</Col>
						<Col span={8}>
							<Card hoverable cover={<IconFont type="icon-zhengshu-copy-copy" />}>
								<Meta title="电子数据区块链存证证书查看" />
							</Card>
						</Col>
					</Row>
					<Button type="primary" size="large" onClick={() => goto("/existingEvidence")}>
						立即体验
						<RightOutlined />
					</Button>
				</div>
			</div>
			<div className={isAdd6 ? "c-2 noMargin" : "c-2"}>
				<div>
					<div className="t-1">水印比对</div>
					<div className="t-2">依托华科大领先的水印识别技术，比对成功率99%以上，有效识别侵权行为</div>
					<Row>
						<Col span={8}>
							<Card hoverable cover={<IconFont type="icon-shimingrenzheng-copy" />}>
								<Meta title="实名认证" />
							</Card>
						</Col>
						<Col span={8}>
							<Card hoverable cover={<IconFont type="icon-wj-wjsc" />}>
								<Meta title="上传作品" />
							</Card>
						</Col>
						<Col span={8}>
							<Card hoverable cover={<IconFont type="icon-zhangdan" />}>
								<Meta title="支付费用" />
							</Card>
						</Col>
					</Row>
					<Row>
						<Col span={8}>
							<Card hoverable cover={<IconFont type="icon-shuiyin" />}>
								<Meta title="水印识别" />
							</Card>
						</Col>
						<Col span={8}>
							<Card hoverable cover={<IconFont type="icon-baocunxiaozhengwenjian" />}>
								<Meta title="证据存证" />
							</Card>
						</Col>
						<Col span={8}>
							<Card hoverable cover={<IconFont type="icon-zhengshu-copy-copy" />}>
								<Meta title="信息查看" />
							</Card>
						</Col>
					</Row>
					<Button type="primary" size="large" onClick={() => goto("/obtainEvidence")}>
						立即体验
						<RightOutlined />
					</Button>
				</div>
			</div>
			<div className={isAdd7 ? "c-2 nobackground noMargin" : "c-2 nobackground"}>
				<div>
					<div className="t-1">侵权监测</div>
					<div className="t-2">监测可覆盖全国1w＋常用站点，全球超10w＋站点，自由选择监测站点，短信即时通知侵权信息。</div>
					<Row>
						<Col span={8}>
							<Card hoverable cover={<IconFont type="icon-shimingrenzheng-copy" />}>
								<Meta title="实名认证" />
							</Card>
						</Col>
						<Col span={8}>
							<Card hoverable cover={<IconFont type="icon-wj-wjsc" />}>
								<Meta title="作品选择" />
							</Card>
						</Col>
						<Col span={8}>
							<Card hoverable cover={<IconFont type="icon-zhangdan" />}>
								<Meta title="支付费用" />
							</Card>
						</Col>
					</Row>
					<Row>
						<Col span={8}>
							<Card hoverable cover={<IconFont type="icon-fangdajing-copy" />}>
								<Meta title="侵权监测" />
							</Card>
						</Col>
						<Col span={8}>
							<Card hoverable cover={<IconFont type="icon-qudanguanli-copy" />}>
								<Meta title="一键比对" />
							</Card>
						</Col>
						<Col span={8}>
							<Card hoverable cover={<IconFont type="icon-huojian-copy" />}>
								<Meta title="急速响应" />
							</Card>
						</Col>
					</Row>
					<Button type="primary" size="large" onClick={() => goto("/fullDetection")}>
						立即体验
						<RightOutlined />
					</Button>
				</div>
			</div>
			<div className="footer">
				{/* <div>
					<svg
						width="2560px"
						height="100px"
						xmlns="http://www.w3.org/2000/svg"
						preserveAspectRatio="none"
						x="0px"
						y="0px"
						viewBox="0 0 2560 100"
					>
						<polygon points="2560 0 2560 100 0 100"></polygon>
					</svg>
				</div> */}
				<div>
					<div className="t-1">为什么选择答设版权保护平台</div>
					<Row>
						<Col span={6}>
							<Card hoverable cover={<IconFont type="icon-kexuejishu--copy" />}>
								<Meta
									title="前沿技术支撑"
									description="平台依托于区块链和AI技术，加持金融级加密算法，为每一层服务提供更安全可信的基石"
								/>
							</Card>
						</Col>
						<Col span={6}>
							<Card hoverable cover={<IconFont type="icon-wenjianbaohu-copy" />}>
								<Meta
									title="一站式版权保护"
									description="将分散的业务环节整合成线上一站式服务，集版权存证、侵权监测、链上比对、版权维权为一体,让原创作者机构“一次都不跑”就实现版权保护"
								/>
							</Card>
						</Col>
						<Col span={6}>
							<Card hoverable cover={<IconFont type="icon-zengchangshuai-copy" />}>
								<Meta
									title="降本增效"
									description="有效提高因权属问题产生版权纠纷的解决效率，因区块链存证、在线公证版权登记，证据公信力强，有效减少诉讼周期，降低诉讼费用"
								/>
							</Card>
						</Col>
						<Col span={6}>
							<Card hoverable cover={<IconFont type="icon-tiaozhengfeiyong-copy" />}>
								<Meta
									title="费用低流程简"
									description="平台套餐丰富，可按需选择，价格较同类产品更低。所有业务在线填写，数据报送官方审核，并支持多种api的对接"
								/>
							</Card>
						</Col>
					</Row>
					<Row className="footerJS">
						<Col span={6}>
							<div className="footerJS_1">
								<div>
									<img src={logo_comp} />
								</div>
								<a href={aUrl} target={target}>
									www.dctechc.com
								</a>
							</div>
						</Col>
						<Col span={6}>
							<div className="footerJS_2">
								<div className="t">企业简介</div>
								<ul>
									<li>武汉迭驰科技有限责任公司</li>
								</ul>
							</div>
						</Col>
						<Col span={6}>
							<div className="footerJS_2">
								<div className="t">专注领域</div>
								<ul>
									<li>数据智能与金融风控</li>
									<li>云数据的AI智能决策</li>
									<li>科技赋能自动化审计</li>
								</ul>
							</div>
						</Col>
						<Col span={6}>
							<div className="footerJS_2">
								<div className="t">联系我们</div>
								<div className="wx">
									<img src={wx} alt="" />
									<ul>
										<li>地址：武汉·东湖新技术开发区宜科中心</li>
										<li>电话：+86 - 027 - 59311389</li>
										<li>网址：www.dctechc.com</li>
									</ul>
								</div>
							</div>
						</Col>
					</Row>
					<div className="footerSpan">鄂ICP备 2022002907 号 技术支持：武汉迭驰科技有限责任公司</div>
				</div>
			</div>
			{/* <div className={isShow ? "qrCode qrShow" : "qrCode"}>
				<Popover content={content}>
					<img src={wx} alt="" />
				</Popover>
			</div> */}
		</div>
	);
};

export default Index;
