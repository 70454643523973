// ? 全局不动配置项 只做导出不做修改

// * 首页地址（默认）
export const HOME_URL: string = "/home";

// * Tabs（黑名单地址，不需要添加到 tabs 的路由地址，暂时没用）
export const TABS_BLACK_LIST: string[] = ["/403", "/404", "/500", "/layout", "/login", "/dataScreen"];

// * 高德地图key
export const MAP_KEY: string = "";

export interface IConfig {
    env: string // 开发环境
    title: string // 项目title
    apiUrl: string // api请求地址
}

const development: IConfig = {
    env: "development",
    title: "开发",
    apiUrl: "http://dashe-dev.dcepstc.com:7777/interface",
}
const devtest: IConfig = {
    env: "devtest",
    title: "测试",
    apiUrl: "http://dashe-test.dcepstc.com:8888/interface",
}

const devprod: IConfig = {
    env: "devprod",
    title: "生产",
    apiUrl: "http://dashe.dctechc.com/interface",
}

const production: IConfig = {
	env: "production",
	title: "生产",
	apiUrl: "/interface",
}

const options = {development,devtest,devprod,production}

export const apiUrl = options[import.meta.env.MODE].apiUrl
