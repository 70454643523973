import { useState } from "react";
import { Alert, Button, Input, Modal, Spin, Tabs, TabsProps } from "antd";
import { connect } from "react-redux";
import { Vertify } from "@alex_xu/react-slider-vertify";
import { sendSMS } from "@/api/modules/login";
import FindPassword from "@/components/findPassword";

const Mobilelogin = (props: any) => {
	const { showModal, changePage } = props;
	// const navigate = useNavigate();showModal
	const [loading, setLoading] = useState<boolean>(false);

	const [isShowTime, setIsShowTime] = useState<boolean>(true);

	const [countDown, setCountDown] = useState<number>(60);

	const [errMsg, setErrMsg] = useState<string>("");

	const [phone, setPhone] = useState<string>("");
	const [code, setCode] = useState<string>("");
	const [password, setPassword] = useState<string>("");

	const [visibleShow, setVisibleShow] = useState<boolean>(false);
	const [type, setType] = useState("1");
	const [isModalOpen, setIsModalOpen] = useState(false);

	const [isModalOpen2, setIsModalOpen2] = useState(false);

	const items0: TabsProps["items"] = [
		{
			key: "1",
			label: `账号密码登录`
		},
		{
			key: "2",
			label: `验证码登录`
		}
	];

	const onChange = (key: string) => {
		setErrMsg("");
		setType(key);
	};

	// 登录
	const onFinish = async () => {
		setErrMsg("");
		let msg = "";
		if (!phone) {
			msg = "手机号不能为空";
			setErrMsg(msg);
			return;
		}

		let reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;

		if (!reg_tel.test(phone)) {
			msg = "手机号格式不正确";
			setErrMsg(msg);
			return;
		}

		if (type == "1" && !password.trim()) {
			msg = "密码不能为空";
			setErrMsg(msg);
			return;
		}

		if (type == "2" && !code) {
			msg = "验证码不能为空";
			setErrMsg(msg);
			return;
		}

		let reg = /^[0-9]\d{5}$/;

		if (type == "2" && !reg.test(code)) {
			msg = "验证码格式不正确";
			setErrMsg(msg);
			return;
		}
		try {
			setLoading(true);
			if (type == "1") {
				showModal(phone, password.trim(), type);
			} else {
				showModal(phone, code, type);
			}
		} finally {
			setLoading(false);
		}
	};
	const getVertify = () => {
		setErrMsg("");
		let msg = "";
		if (!phone) {
			msg = "手机号不能为空";
			setErrMsg(msg);
			return;
		}

		let reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;

		if (!reg_tel.test(phone)) {
			msg = "手机号格式不正确";
			setErrMsg(msg);
			return;
		}
		setIsModalOpen(true);
		setVisibleShow(true);
	};

	const getCode = () => {
		setTimeout(() => {
			setIsShowTime(false);
			setVisibleShow(false);
			setTimeout(() => {
				setIsModalOpen(false);
			}, 100);
			setCountDown(60);
			let num = 60;
			let setInter = setInterval(() => {
				num = num - 1;
				setCountDown(num);
				if (num === 0) {
					setIsShowTime(true);
					clearInterval(setInter);
				}
			}, 1000);
		}, 1000);
		sendSMS({ phone: phone });
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};
	const handleCancel2 = () => {
		setIsModalOpen2(false);
	};
	const reg = () => {
		changePage("2");
	};

	const find = () => {
		setIsModalOpen2(true);
	};

	const changeModal = () => {
		setIsModalOpen2(false);
	};

	return (
		<div className="loginPage">
			<div className="login-logo">
				<span className="logo-text">您好！欢迎登录答设版权保护平台</span>
			</div>

			<Tabs activeKey={type} items={items0} onChange={onChange} />

			{type == "1" ? (
				<>
					<div className="login-content">
						<span>手机号</span>
						<div>
							<div className="border86">+86</div>
							<Input placeholder="请输入手机号码" maxLength={11} value={phone} onChange={e => setPhone(e.target.value)} />
						</div>
						<span className="spanM">密码</span>
						<div>
							<Input
								type="password"
								placeholder="请输入密码"
								minLength={8}
								value={password}
								onChange={e => setPassword(e.target.value)}
							/>
						</div>
						{errMsg ? <Alert type="error" message={errMsg} banner /> : ""}
					</div>
					<Button block size="large" type="primary" loading={loading} onClick={onFinish}>
						登录
					</Button>
					<div className="login-bs">
						<span onClick={find}>忘记密码?</span>
						<span onClick={reg}>立即注册</span>
					</div>
				</>
			) : (
				<>
					<div className="login-content">
						<span>手机号</span>
						<div>
							<div className="border86">+86</div>
							<Input placeholder="请输入手机号码" maxLength={11} value={phone} onChange={e => setPhone(e.target.value)} />
						</div>
						<span className="spanM">验证码</span>
						<div>
							<Input placeholder="请输入短信验证码" maxLength={6} value={code} onChange={e => setCode(e.target.value)} />
							<div className="borderleft">
								{isShowTime ? (
									<span className="span1" onClick={getVertify}>
										获取验证码
									</span>
								) : (
									<span className="span2">{countDown}秒后重发</span>
								)}
							</div>
						</div>
						{errMsg ? <Alert type="error" message={errMsg} banner /> : ""}
					</div>
					<Button block size="large" type="primary" loading={loading} onClick={onFinish}>
						登录
					</Button>
					<div className="login-ts">
						<span onClick={reg}>立即注册</span>
					</div>
				</>
			)}

			<Modal zIndex={999} title="安全验证" open={isModalOpen} onCancel={handleCancel} footer={null} width={500}>
				<Spin className="m-vertify-s" size="large"/>
				<Vertify width={450} visible={visibleShow} height={160} onSuccess={getCode} text={"向右滑动填充拼图"} />
			</Modal>

			<Modal zIndex={888} title="找回密码" open={isModalOpen2} onCancel={handleCancel2} footer={null} width={500}>
				<FindPassword changeModal={changeModal} />
			</Modal>
		</div>
	);
};

const mapDispatchToProps = {};
export default connect(null, mapDispatchToProps)(Mobilelogin);
