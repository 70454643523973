import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Layout } from "antd";
import { setAuthButtons } from "@/redux/modules/auth/action";
import { updateCollapse } from "@/redux/modules/menu/action";
import { setUser } from "@/redux/modules/global/action";
// import { getAuthorButtons } from "@/api/modules/login";
import { connect } from "react-redux";
import LayoutMenu from "./components/Menu";
import LayoutHeader from "./components/Header";
// import LayoutTabs from "./components/Tabs";
// import LayoutFooter from "./components/Footer";
import "./index.less";
// import { userDetail } from "@/api/modules/login";
// import { evidenceCollValue } from "@/api/modules/business";

const LayoutIndex = (props: any) => {
	const { Sider, Content } = Layout;
	const { isCollapse, updateCollapse } = props;
	const location = useLocation();

	// // 获取按钮权限列表
	// const getAuthButtonsList = async () => {
	// 	const { data } = await getAuthorButtons();
	// 	setAuthButtons(data);
	// };

	// // 监听窗口大小变化
	// const listeningWindow = () => {
	// 	window.onresize = () => {
	// 		return (() => {
	// 			let screenWidth = document.body.clientWidth;
	// 			if (!isCollapse && screenWidth < 1200) updateCollapse(true);
	// 			if (!isCollapse && screenWidth > 1200) updateCollapse(false);
	// 		})();
	// 	};
	// };

	useEffect(() => {
		// console.log(isCollapse);
		// listeningWindow();
		// getAuthButtonsList();
		// if (location.pathname == "/account") {
		// 	updateCollapse(true);
		// } else {
		// 	updateCollapse(false);
		// }
		updateCollapse(false);
	}, [location]);

	return (
		// 这里不用 Layout 组件原因是切换页面时样式会先错乱然后在正常显示，造成页面闪屏效果
		<section className="container">
			{/* <Sider trigger={null} collapsed={props.isCollapse} width={220} theme="dark">
				<LayoutMenu></LayoutMenu>
			</Sider>
			<Layout>
				<LayoutHeader></LayoutHeader>
				<LayoutTabs></LayoutTabs>
				<Content>
					<Outlet></Outlet>
				</Content>
				<LayoutFooter></LayoutFooter>
			</Layout> */}

			<LayoutHeader></LayoutHeader>
			<Layout>
				{!isCollapse ? (
					<Sider width={220}>
						<LayoutMenu></LayoutMenu>
					</Sider>
				) : (
					""
				)}
				<Content>
					<Outlet></Outlet>
				</Content>
			</Layout>
		</section>
	);
};

const mapStateToProps = (state: any) => state.menu;
const mapDispatchToProps = { setAuthButtons, updateCollapse, setUser };
export default connect(mapStateToProps, mapDispatchToProps)(LayoutIndex);
