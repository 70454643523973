import {Col, Input, Modal, Row, Spin, Form, message, Button, Select, Result} from "antd";
import {forwardRef, useImperativeHandle, useRef, useState} from "react";
import {agreementApply, agreementPreInfo, agreementSign} from "@/api/modules/login"
import {CloseCircleOutlined,CheckCircleOutlined} from "@ant-design/icons";

// eslint-disable-next-line react/display-name
export default forwardRef((props:any,ref)=>{
	const [open,setOpen] = useState<boolean>(false)
	const [confirmLoading,setConfirmLoading] = useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(false)
	const [form] = Form.useForm()
	const [messageApi,contextHolder] = message.useMessage()
	const [currentUser, setCurrentUser] = useState<any>();
	const [bank, setBank] = useState<any>();
	const [smsCountdown, setSmsCountdown] = useState(60)
	const [smsWaiting, setSmsWaiting] = useState(false)
	const smsRef = useRef(null)
	const [result, setResult] = useState(0)

	useImperativeHandle(ref,()=>({
		openModal:(bank)=>{
			setOpen(true)
			setLoading(true)
			setBank(bank)
			agreementPreInfo({}).then((res:any)=>{
				setCurrentUser(res.data)
				form.setFieldsValue(res.data)
				form.setFieldValue("bankName",bank.cnName)
				setLoading(false)
			}).catch(()=>{
				setLoading(false)}
			)
		}
	}))
	const resetSms = ()=>{
		clearInterval(smsRef.current)
		setSmsCountdown(60)
		setSmsWaiting(false)
	}
	const closeModal = ()=>{
		setLoading(false)
		setConfirmLoading(false)
		form.resetFields()
		resetSms()
		setOpen(false)
		setResult(0)
	}
	const handleOk=()=>{
		form.submit()
	}
	const handleSubmit= (val:any)=>{
		setLoading(true)
		setConfirmLoading(true)
		agreementSign({phone:val.phone, smsCode:val.smsCode}).then((res:any)=>{
			setResult(res.data?.contractNo?1:2)
			setLoading(false)
			setConfirmLoading(false)
		}).catch(()=>{
			setLoading(false)
			setConfirmLoading(false)
		})
	}
	const validMsgs = {
		required:"请输入${label}",
	}
	const getValidCode = ()=>{
		setSmsWaiting(true)
		let num = 60
		agreementApply({eooCode:bank.name,phone:currentUser.phone}).then(()=>{
			smsRef.current = setInterval(()=>{
				num --
				setSmsCountdown(num)
				if(num<=0){
					resetSms()
				}
			},1000)
			messageApi.open({
				type:'success',
				content:'验证短信发送成功'
			})
		}).catch(()=>{
			resetSms()
		})
	}
	return (
		<>
			<Modal title={<div style={{display:'flex',justifyContent:'center'}}>数币支付协议签约</div>} maskClosable={false} footer={result?null:<Spin spinning={confirmLoading}><div style={{display:'flex',justifyContent:'center'}}><Button onClick={()=>{handleOk()}} type='primary'>立即签约</Button></div></Spin>}  open={open} onCancel={closeModal} width={600}>
				<Spin spinning={loading}>
					{
						result?  <Result
								icon={result===1?<CheckCircleOutlined style={{transform:'scale(3)' }}/>:<CloseCircleOutlined style={{transform:'scale(3)' }}/>}
								status={result===1?'success':'error'}
								title={result===1?'签约成功':'签约失败'}
								extra={[
									<Button key="returnKey" onClick={()=>{
										closeModal()
										props.getAgreeStatus()
									}} type="primary">返回收银台</Button>
								]}
							/>:
							<Form style={{marginTop:'30px'}} form={form} onFinish={handleSubmit} wrapperCol={{span:16}} labelCol={{span:10}} validateMessages={validMsgs}>
								<Row>
									<Col span={16}>
										<Form.Item label="协议支付签约银行" name="bankName" rules={[{required:true}]}>
											<Input disabled/>
										</Form.Item>
									</Col>
								</Row>
								<Row>
									<Col span={16}>
										<Form.Item label="证件类型" name="idcardType" rules={[{required:true}]}>
											<Select disabled>
												<Select.Option key='1' value='1'>居民身份证</Select.Option>
											</Select>
										</Form.Item>
									</Col>
								</Row>
								<Row>
									<Col span={16}>
										<Form.Item label="证件号码" name="idcardNo" rules={[{required:true}]}>
											<Input disabled/>
										</Form.Item>
									</Col>
								</Row>
								<Row>
									<Col span={16}>
										<Form.Item label="客户名称" name="idcardName" rules={[{required:true}]}>
											<Input disabled/>
										</Form.Item>
									</Col>
								</Row>
								<Row>
									<Col span={16}>
										<Form.Item label="手机号" name="phone" rules={[{required:true}]}>
											<Input disabled/>
										</Form.Item>
									</Col>
								</Row>
								<Row>
									<Col span={16}>
										<Form.Item label="验证码" name="smsCode" rules={[{required:true}]}>
											<Input/>
										</Form.Item>
									</Col>
									<Col offset={1} span={6}>
										<Button disabled={smsWaiting} onClick={()=>{getValidCode()}}>{smsWaiting?`已发送${smsCountdown?smsCountdown+'s':''}`:'获取验证码'}</Button>
									</Col>
								</Row>
							</Form>
					}

				</Spin>
			</Modal>
			{ contextHolder }
		</>
	)
})
