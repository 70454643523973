// import LoginForm from "./components/LoginForm";
import Mobilelogin from "./components/Login";
// import SwitchDark from "@/components/SwitchDark";
// import loginLeft from "@/assets/images/login/login.png";
import logo from "@/assets/images/logo.png";
import "./index.less";
import { useEffect, useState } from "react";
import { Alert, Button, message, Modal } from "antd";
import { login, phoneCheck, userDetail, userPasswordLogin, userPhoneLogin } from "@/api/modules/login";
import { setToken, setUser } from "@/redux/modules/global/action";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
// import { localSet } from "@/utils/util";
// import { evidenceCollValue } from "@/api/modules/business";
import { HOME_URL } from "@/config/config";
// import logo_a_1 from "@/assets/images/login/login-a-1.png";
// import logo_a_2 from "@/assets/images/login/login-a-2.png";
import { IconFont } from "@/assets/iconfont";
import Register from "./components/register";

const Login = (props: any) => {
	const navigate = useNavigate();
	const { state } = useLocation();
	const { setToken, setUser } = props;
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isActive1, setIsActive1] = useState(false);
	const [isActive2, setIsActive2] = useState(false);

	const [page, setPage] = useState("1");
	const [userType, setUserType] = useState("");

	const [phone1, setPhone1] = useState<string>("");
	const [code1, setCode1] = useState<string>("");
	const [password1, setPassword1] = useState<string>("");

	useEffect(() => {
		if (state == "2") {
			setPage("2");
		}
	}, []);

	const showModal = async (phone: any, code: any, type: any) => {
		// localSet("phone", phone1);
		// setPhone(phone1);
		// setCode(code1);
		onLogin(phone, code, type);
		// let { data }: any = await phoneCheck({ phone: phone1 });
		// console.log(data);
		// if (!data.userType) {
		// 	setIsModalOpen(true);
		// } else {
		// 	setUserType(data.userType);
		// }
	};

	const showModal2 = async (phone, password, code) => {
		// localSet("phone", phone1);
		setPhone1(phone);
		setCode1(code);
		setPassword1(password);
		let { data }: any = await phoneCheck({ phone: phone });
		console.log(data);
		if (!data) return;
		if (!data.userType) {
			setIsModalOpen(true);
		} else {
			const modal = Modal.error({});
			modal.update({
				title: "错误信息",
				content: "该手机号已被注册。"
			});
		}
	};

	const onLogin2 = async () => {
		const { data }: any = await login({ phone: phone1, password: password1, code: code1, userType: userType, sourceFrom: "1" });
		if (!data) return;
		setToken(data.token);
		message.success("注册成功！");
		getuserDetail();
	};

	const changePage = page => {
		setPage(page);
	};

	const goto = async () => {
		if (!isActive1 && !isActive2) {
			message.warning("请先选择身份！");
			return;
		}
		onLogin2();
	};
	const onLogin = async (phone, code, type) => {
		// const { data }: any = await login({ phone: phone1, code: code1, sourceFrom: "1" });
		// // console.log(data)
		// // localStorage.setItem("idcardName", data.idcardName);
		// // localStorage.setItem("idcardNo", data.idcardNo);
		// // localStorage.setItem("mobile", data.mobile);
		// if (!data) return;
		// setToken(data.token);
		// // message.success("登录成功！");
		// getuserDetail();

		// setIsModalOpen(false);
		if (type == "1") {
			const { data }: any = await userPasswordLogin({ phone: phone, password: code, sourceFrom: "1" });
			if (!data) return;
			setToken(data.token);
		} else {
			const { data }: any = await userPhoneLogin({ phone: phone, code: code, sourceFrom: "1" });
			console.log(data);
			if (!data) return;
			setToken(data.token);
		}

		// message.success("登录成功！");
		getuserDetail();
	};

	const getuserDetail = async () => {
		try {
			let A: any = await userDetail({});
			// console.log(A);
			// localStorage.setItem("userInfo", JSON.stringify(A.data));
			setUser(A.data);

			// let B: any = await evidenceCollValue({});
			// console.log(B);
			// localStorage.setItem("postage", JSON.stringify(B.data));

			navigate("/existingEvidence");
		} catch (error) {
			console.log(error);
		}
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const changeCard = (num: number) => {
		if (num === 1) {
			setIsActive1(true);
			setIsActive2(false);
			setUserType("1");
		} else {
			setIsActive1(false);
			setIsActive2(true);
			setUserType("2");
		}
	};
	const goHome = () => {
		navigate(HOME_URL);
	};
	return (
		<div className="login">
			{/* <SwitchDark />
			<div className="login-box">
				<div className="login-left">
					<img src={loginLeft} alt="login" />
				</div>
				<div className="login-form">
					<div className="login-logo">
						<img className="login-icon" src={logo} alt="logo" />
						<span className="logo-text">数字作品保护平台</span>
					</div>
					<Mobilelogin showModal={showModal} />
				</div>
			</div> */}
			<div className="login-left">
				{/* <img src={loginLeft} alt="login" /> */}
				<div className="login-logo" onClick={goHome}>
					<img className="login-icon" src={logo} alt="logo" />
					<span className="logo-text">答设版权保护平台</span>
				</div>
			</div>
			<div className="login-right">
				<div className="login-right-c">
					{page == "1" ? (
						<Mobilelogin showModal={showModal} changePage={changePage} />
					) : (
						<Register showModal2={showModal2} changePage={changePage} />
					)}
				</div>
			</div>
			<Modal title="选择身份" width={580} open={isModalOpen} footer={null} onCancel={handleCancel}>
				<div className="loginModal">
					<Alert
						message="提示：如果您选择个人账户，存证后，您作品的著作权将归属于个人，如果您选择企业账户，存证后，您作品的著作权将归属于企业。请谨慎选择身份，一旦选定，后续不能更改"
						type="warning"
						showIcon
						banner
					/>
					<div className="modalc">
						<div className="modalc-t">请选择身份</div>
						<div className="imgs">
							<div>
								<div className={isActive1 ? "imgdiv active" : "imgdiv"} onClick={() => changeCard(1)}>
									{/* <img className="login-icon" src={logo_a_1} /> */}
									<IconFont type="icon-a-07-zhanghuguanli-copy" />
								</div>
								<div className="imgfont">个人账户</div>
							</div>
							<div>
								<div className={isActive2 ? "imgdiv active" : "imgdiv"} onClick={() => changeCard(2)}>
									{/* <img className="login-icon" src={logo_a_2} /> */}
									<IconFont type="icon-qiyezhanghu-copy" />
								</div>
								<div className="imgfont">企业账户</div>
							</div>
						</div>
					</div>
					<Button block size="large" type="primary" onClick={goto}>
						答设，一站式保护保护平台，立即进入
					</Button>
				</div>
			</Modal>
		</div>
	);
};

const mapDispatchToProps = { setToken, setUser };
export default connect(null, mapDispatchToProps)(Login);
