import { sendSMS, userUpdatePassword } from "@/api/modules/login";
import { Vertify } from "@alex_xu/react-slider-vertify";
import { Alert, Button, Input, Modal, Spin, message } from "antd";
import { useEffect, useRef, useState } from "react";
import "./index.less";
import { encryption } from "@/utils/pubilc/encryption";

const FindPassword = (props: any) => {
	const { changeModal, mobile, type } = props;
	const [phone2, setPhone2] = useState<string>("");
	const [code2, setCode2] = useState<string>("");
	const [password2, setPassword2] = useState<string>("");
	const [surePassword2, setSurePassword2] = useState<string>("");
	const [isShowTime2, setIsShowTime2] = useState<boolean>(true);
	const [countDown2, setCountDown2] = useState<number>(60);
	const [errMsg2, setErrMsg2] = useState<string>("");
	const [visibleShow, setVisibleShow] = useState<boolean>(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const setI = useRef(null);

	useEffect(() => {
		setPhone2(mobile);
	}, [mobile]);

	const getVertify2 = () => {
		setErrMsg2("");
		let msg = "";
		if (!phone2) {
			msg = "手机号不能为空";
			setErrMsg2(msg);
			return;
		}

		let reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;

		if (!reg_tel.test(phone2)) {
			msg = "手机号格式不正确";
			setErrMsg2(msg);
			return;
		}
		setIsModalOpen(true);
		setVisibleShow(true);
	};

	const findPasswrod = async () => {
		let msg = "";
		if (!phone2) {
			msg = "手机号不能为空";
			setErrMsg2(msg);
			return;
		}

		let reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;

		if (!reg_tel.test(phone2)) {
			msg = "手机号格式不正确";
			setErrMsg2(msg);
			return;
		}

		if (!code2) {
			msg = "验证码不能为空";
			setErrMsg2(msg);
			return;
		}

		let reg = /^[0-9]\d{5}$/;

		if (!reg.test(code2)) {
			msg = "验证码格式不正确";
			setErrMsg2(msg);
			return;
		}

		if (!password2.trim()) {
			msg = "密码不能为空";
			setErrMsg2(msg);
			return;
		}

		let reg_pas = /(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[a-zA-Z0-9]{8,16}/;

		if (!reg_pas.test(password2.trim())) {
			msg = "密码格式不正确，请输入大小写字母+数字组合密码";
			setErrMsg2(msg);
			return;
		}

		if (surePassword2.trim() !== password2.trim()) {
			msg = "两次密码输入不一致";
			setErrMsg2(msg);
			return;
		}

		let A: any = await userUpdatePassword({
			phone: phone2,
			password: password2.trim(),
			code: code2,
			surePassword: surePassword2.trim()
		});
		console.log(A);
		// if (!data) return;
		if (A.code == 200) {
			message.success("密码重置成功！");
			// setIsModalOpen2(false);
			closeModal();
		}
	};
	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const getCode = () => {
		setTimeout(() => {
			setIsShowTime2(false);
			setVisibleShow(false);
			setTimeout(() => {
				setIsModalOpen(false);
			}, 100);
			setCountDown2(60);
			let num = 60;
			setI.current = setInterval(() => {
				num = num - 1;
				setCountDown2(num);
				if (num === 0) {
					setIsShowTime2(true);
					clearInterval(setI.current);
				}
			}, 1000);
		}, 1000);
		sendSMS({ phone: phone2 });
	};

	const closeModal = () => {
		clearInterval(setI.current);
		if (type != "1") setPhone2("");
		setCode2("");
		setPassword2("");
		setSurePassword2("");
		setErrMsg2("");
		setIsShowTime2(true);
		setCountDown2(0);
		changeModal();
	};

	return (
		<>
			<div className="findpasswordpage">
				<div className="login-content">
					<div>
						<span>手机号</span>
						{mobile ? (
							<div className="disabledDiv">
								<div className="border86">+86</div>
								<Input disabled value={encryption(mobile)} />
							</div>
						) : (
							<div>
								<div className="border86">+86</div>
								<Input placeholder="请输入手机号码" maxLength={11} value={phone2} onChange={e => setPhone2(e.target.value)} />
							</div>
						)}
					</div>
					<div>
						<span className="spanM">验证码</span>
						<div tabIndex={-1}>
							<Input placeholder="请输入短信验证码" maxLength={6} value={code2} onChange={e => setCode2(e.target.value)} />
							<div className="borderleft">
								{isShowTime2 ? (
									<span className="span1" onClick={getVertify2}>
										获取验证码
									</span>
								) : (
									<span className="span2">{countDown2}秒后重发</span>
								)}
							</div>
						</div>
					</div>
					<div>
						<span className="spanM">新密码</span>
						<div className="noborder">
							<Input.Password
								placeholder="8~16位，必须包含大小写字母、数字"
								minLength={8}
								maxLength={16}
								value={password2}
								onChange={e => setPassword2(e.target.value)}
							/>
						</div>
					</div>
					<div>
						<span className="spanM">新密码确认</span>
						<div className="noborder">
							<Input.Password
								placeholder="请再次输入密码"
								minLength={8}
								maxLength={16}
								value={surePassword2}
								onChange={e => setSurePassword2(e.target.value)}
							/>
						</div>
					</div>
				</div>
				{errMsg2 ? <Alert type="error" message={errMsg2} banner /> : ""}
				<div className="login-buttons">
					<Button block size="large" onClick={closeModal}>
						取消
					</Button>
					<Button block size="large" type="primary" onClick={findPasswrod}>
						确认
					</Button>
				</div>
			</div>
			<Modal zIndex={999} title="安全验证" open={isModalOpen} onCancel={handleCancel} footer={null} width={500}>
				<Spin className="m-vertify-s" size="large" />
				<Vertify width={450} visible={visibleShow} height={160} onSuccess={getCode} text={"向右滑动填充拼图"} />
			</Modal>
		</>
	);
};

export default FindPassword;
