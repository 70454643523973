import { Col, Collapse, Empty, Row, theme } from "antd";
import { useEffect, useState } from "react";
import { CaretRightOutlined } from "@ant-design/icons";
import { questionList } from "@/api/modules/business";

interface Params1 {
	questionTitle: string;
	questionAnswer: string;
	themeId: string;
}
interface Params {
	questionInfoRespList: Array<Params1>;
}
const HelpCenter = () => {
	const { Panel } = Collapse;
	const { token } = theme.useToken();
	// const [list, setList]: any = useState<Params[]>([]);
	const [list1, setList1]: any = useState<Params[]>([]);
	const [list2, setList2]: any = useState<Params[]>([]);
	const panelStyle = {
		marginBottom: 24,
		background: token.colorFillAlter,
		borderRadius: token.borderRadiusLG,
		border: "none"
	};

	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		const { data }: any = await questionList({});
		if (!data) return;
		// setList(data);
		let arr1 = [],
			arr2 = [];
		for (let i = 0; i <= data.length; i++) {
			if (i % 2 == 0) {
				if (data[i]) arr1.push(data[i]);
			} else {
				if (data[i]) arr2.push(data[i]);
			}
		}
		setList1(arr1);
		setList2(arr2);
	};

	return (
		<>
			<div className="helpPage">
				<div className="t">你关心的问题都在这里</div>
				<div className="c">
					<div>
						<Row>
							{list1.map(item => (
								<Col span={24} key={item.themeId}>
									<div className="title">{item.themeName}</div>
									{item.questionInfoRespList.map(option => (
										<Collapse
											accordion
											expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
											bordered={false}
											style={{ background: token.colorBgContainer }}
											key={option.questionId}
										>
											<Panel header={option.questionTitle} key={option.themeId} style={panelStyle}>
												<p>{option.questionAnswer}</p>
											</Panel>
										</Collapse>
									))}
								</Col>
							))}
						</Row>
						<Row>
							{list2.map(item => (
								<Col span={24} key={item.themeId}>
									<div className="title">{item.themeName}</div>
									{item.questionInfoRespList.map(option => (
										<Collapse
											accordion
											expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
											bordered={false}
											style={{ background: token.colorBgContainer }}
											key={option.questionId}
										>
											<Panel header={option.questionTitle} key={option.themeId} style={panelStyle}>
												<p>{option.questionAnswer}</p>
											</Panel>
										</Collapse>
									))}
								</Col>
							))}
						</Row>
					</div>
				</div>
				{list1.length == 0 ? <Empty /> : ""}
			</div>
		</>
	);
};

export default HelpCenter;
