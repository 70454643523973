import { useState } from "react";
import { Alert, Button, Checkbox, Input, Modal, message } from "antd";
import { connect } from "react-redux";
import { Vertify } from "@alex_xu/react-slider-vertify";
import { sendSMS } from "@/api/modules/login";
import { CheckboxChangeEvent } from "antd/es/checkbox";

const Register = (props: any) => {
	const { changePage, showModal2 } = props;
	// const navigate = useNavigate();showModal
	const [loading, setLoading] = useState<boolean>(false);

	const [isShowTime, setIsShowTime] = useState<boolean>(true);
	const [countDown, setCountDown] = useState<number>(60);
	const [errMsg, setErrMsg] = useState<string>("");

	const [phone, setPhone] = useState<string>("");
	const [code, setCode] = useState<string>("");
	const [password, setPassword] = useState<string>("");

	const [visibleShow, setVisibleShow] = useState<boolean>(true);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [checked, setChecked]: any = useState(false);
	const [isModalOpen2, setIsModalOpen2] = useState(false);

	// 登录
	const onFinish = async () => {
		setErrMsg("");
		let msg = "";
		if (!phone) {
			msg = "手机号不能为空";
			setErrMsg(msg);
			return;
		}

		let reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;

		if (!reg_tel.test(phone)) {
			msg = "手机号格式不正确";
			setErrMsg(msg);
			return;
		}

		if (!code) {
			msg = "验证码不能为空";
			setErrMsg(msg);
			return;
		}

		let reg = /^[0-9]\d{5}$/;

		if (!reg.test(code)) {
			msg = "验证码格式不正确";
			setErrMsg(msg);
			return;
		}

		if (!password.trim()) {
			msg = "密码不能为空";
			setErrMsg(msg);
			return;
		}

		let reg_pas = /(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[a-zA-Z0-9]{8,16}/;

		if (!reg_pas.test(password.trim())) {
			msg = "密码格式不正确，请输入大小写字母+数字组合密码";
			setErrMsg(msg);
			return;
		}

		if (!checked) {
			message.error("请先阅读并同意用户隐私协议！");
			return;
		}

		try {
			setLoading(true);
			showModal2(phone, password.trim(), code);
		} finally {
			setLoading(false);
		}
	};
	const getVertify = () => {
		setErrMsg("");
		let msg = "";
		if (!phone) {
			msg = "手机号不能为空";
			setErrMsg(msg);
			return;
		}

		let reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;

		if (!reg_tel.test(phone)) {
			msg = "手机号格式不正确";
			setErrMsg(msg);
			return;
		}
		setVisibleShow(true)
		setIsModalOpen(true);
	};
	const getCode = () => {
		setTimeout(() => {
			setVisibleShow(false);
			setIsShowTime(false);
			setIsModalOpen(false);
			setCountDown(60);
			let num = 60;
			let setInter = setInterval(() => {
				num = num - 1;
				setCountDown(num);
				if (num === 0) {
					setIsShowTime(true);
					clearInterval(setInter);
				}
			}, 1000);
			sendSMS({ phone: phone });
		}, 1000);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const log = () => {
		changePage("1");
	};
	const onChange = (e: CheckboxChangeEvent) => {
		setChecked(e.target.checked);
		if (e.target.checked) {
			setIsModalOpen2(true);
		}
	};
	const handleCancel2 = () => {
		setIsModalOpen2(false);
	};
	return (
		<div className="loginPage">
			<div className="login-logo">
				<span className="logo-text">新用户注册</span>
			</div>

			<div className="login-content">
				<span>手机号</span>
				<div tabIndex={-1}>
					<div className="border86">+86</div>
					<Input placeholder="请输入手机号码" maxLength={11} value={phone} onChange={e => setPhone(e.target.value)} />
				</div>
				<span className="spanM">验证码</span>
				<div tabIndex={-1}>
					<Input placeholder="请输入短信验证码" maxLength={6} value={code} onChange={e => setCode(e.target.value)} />
					<div className="borderleft">
						{isShowTime ? (
							<span className="span1" onClick={getVertify}>
								获取验证码
							</span>
						) : (
							<span className="span2">{countDown}秒后重发</span>
						)}
					</div>
				</div>

				<span className="spanM">密码</span>
				<div className="noborder">
					<Input.Password
						placeholder="8~16位，必须包含大小写字母、数字"
						minLength={8}
						maxLength={16}
						value={password}
						onChange={e => setPassword(e.target.value)}
					/>
				</div>
				{errMsg ? <Alert type="error" message={errMsg} banner /> : ""}
			</div>
			<Checkbox onChange={onChange}>
				我已阅读并同意 <span className="xyspan">《答设平台用户使用协议》</span>
			</Checkbox>
			<Button block size="large" type="primary" loading={loading} onClick={onFinish}>
				注册并登录
			</Button>
			<div className="login-rs">
				<span>
					已有账号？
					<span onClick={log}>直接登录</span>
				</span>
			</div>

			<Modal title="安全验证" open={isModalOpen} onCancel={handleCancel} footer={null} width={500}>
				<Vertify width={450} visible={visibleShow} height={160} onSuccess={getCode} text={"向右滑动填充拼图"} />
			</Modal>
			<Modal title="《答设平台用户使用协议》" open={isModalOpen2} onCancel={handleCancel2} footer={null} width={500} >
				<div className="modeXY">
					<div>
						&emsp;&emsp;欢迎您使用答设数字作品平台服务（以下简称答设），您在使用答设服务前请认真阅读以下 协议（以下简称本协议）。
						答设版权保护平台服务的所有权归武汉迭驰科技有限公司所有。
					</div>
					<br />
					<div>
						<strong>一、答设用户协议的接受</strong>
						<br />
						&emsp;&emsp;1、当您在注册程序过程中在“已阅读，同意本《用户协议》”处打勾“✓”并按照注册程
						序成功注册为答设用户，或您以其他答设允许的方式实际使用答设服务时，即表示您已充分
						阅读、理解并接受本协议的全部内容，并与答设达成协议。请您务必审慎阅读、充分理解各
						条款内容，特别是免除或者限制责任的条款，请您重点阅读并理解加粗提示条款。如您对本
						协议项下任何条款有异议，请停止使用答设服务。
						<br />
						&emsp;&emsp;2、 您确认您具备民事权利能力和完全民事行为能力，有能力同意并遵守本协议，并对您
						本协议项下的全部行为独立承担法律责任。若您不具备前述与您行为相适应的民事行为能力， 则应获得法定监护人的同意。
						<br />
						&emsp;&emsp;3、如您是未满 18 周岁的未成年人，在使用本产品或服务前，应在您的父母或其他监护人
						的监护、指导下共同仔细阅读本协议，并判断是否同意本协议。未成年人行使和履行本协议
						项下的权利和义务视为已获得法定监护人的同意。
						<br />
						&emsp;&emsp;4、本协议是你（以下简称“用户”）与平台软件（以下简称“本软件”）之间关于用户下
						载、安装、使用本软件及相关服务的法律协议。请你在使用本软件及相关服务之前，仔细阅
						读并充分理解本协议的全部内容，特别是免除或限制责任的条款、争议解决和法律适用条款
						等。如你不同意本协议的任何内容，你应立即停止使用本软件及相关服务。如你继续使用本
						软件及相关服务，即表示你已接受本协议的全部约定。
						<br />
						<br />
						<strong>二、本协议的变更和修改</strong>
						<br />
						&emsp;&emsp;答设有权随时对本协议进行修改，并且一旦发生协议条款的变动，答设将以适当的方式（包
						括但不限于弹窗、短信、网站公告等）提醒您更新的内容，以便您及时了解本协议的最新版
						本；用户如果不同意本协议的修改，可以放弃使用或访问本网站或取消已经获得的服务；如
						果用户选择在本协议变更后继续访问或使用本网站，则视为用户已经接受本协议的修改。
						<br /> <br />
						<strong>三、知识产权声明</strong>
						<br />
						&emsp;&emsp;1、本软件是一款为用户提供信息发布、浏览、搜索、交流等功能的平台软件，由平台软件
						开发者（以下简称“开发者”）拥有并运营。开发者依法拥有本软件及相关服务的一切知识
						产权，包括但不限于著作权、商标权、专利权、商业秘密等。
						<br />
						&emsp;&emsp;2、 用户在使用本软件及相关服务过程中产生的任何信息内容（包括但不限于文字、图片、
						视频、音频等），用户保证其为该内容的合法权利人，或已经取得相关权利人的合法授权，
						并对该内容的真实性、合法性、有效性承担全部责任。用户在本软件上发布的信息内容，用
						户同意授予开发者一项免费的、永久的、不可撤销的、可转许可的全球范围内的许可，开发
						者有权以任何形式使用、复制、修改、改编、翻译、传播、展示、表演和分发该内容，并有 权将前述权利转许可给第三方。
						<br />
						&emsp;&emsp;3、用户不得以任何方式侵犯本软件及相关服务的知识产权，不得对本软件及相关服务进行
						反向工程、反向汇编、反向编译或以其他方式尝试获取本软件及相关服务的源代码，不得删
						除或修改本软件及相关服务上显示的任何版权或其他所有权标记。
						<br /> <br />
						<strong>四、用户行为</strong>
						<br />
						用户需要实名认证完成帐号注册，才能正常使用网站提供的服务。任何机构或个人注册和使
						用的互联网用户帐号，必须符合《互联网用户帐号信息管理规定》等相关的法律、法规规定。
						<br />
						&emsp;&emsp;1、用户帐号、密码和安全
						<br />
						&emsp;&emsp;（1）您在注册帐号时必须通过实名认证，才能成为答设的合法用户，得到一个密码和帐号。
						您可以利用帐户，通过本服务合法正当地使用平台提供的各项服务功能。
						<br />
						&emsp;&emsp;（2）用户须对在答设的注册信息的真实性、合法性、有效性承担全部责任，用户不得使用
						他人的名义发布任何信息；当被发现用户冒用他人或机构的名义恶意注册帐号，答设有权立
						即停止提供服务，收回其帐号并由该用户承担由此而产生的一切法律及其他责任。
						<br />
						&emsp;&emsp;（3）您在答设注册和使用的帐号信息，不得有如下情形：
						（一）假冒、仿冒、捏造政党、党政军机关、企事业单位、人民团体和社会组织的名称、 标识等；
						（二）假冒、仿冒、捏造国家（地区）、国际组织的名称、标识等；
						（三）假冒、仿冒、捏造新闻网站、报刊社、广播电视机构、通讯社等新闻媒体的名称、
						标识等，或者擅自使用“新闻”、“报道”等具有新闻属性的名称、标识等；
						（四）假冒、仿冒、恶意关联国家行政区域、机构所在地、标志性建筑物等重要空间的地理名称、标识等；
						（五）以损害公共利益或者谋取不正当利益等为目的，故意夹带二维码、网址、邮箱、
						联系方式等，或者使用同音、谐音、相近的文字、数字、符号和字母等；
						（六）含有名不副实、夸大其词等可能使公众受骗或者产生误解的内容；
						（七）含有法律、行政法规和国家有关规定禁止的其他内容。
						<br />
						&emsp;&emsp;（4）您理解并同意，根据《中华人民共和国网络安全法》《互联网用户账号信息管理规定》
						《互联网信息服务管理办法》等法律、行政法规的规定，答设有权采用任何答设认为有必要
						之措施，对您的帐号信息进行审查，并采用包括但不限于屏蔽、删除、修改、等措施以保证
						信息呈现结果符合法律法规要求，维护清朗网络空间。
						<br />
						&emsp;&emsp;（5）用户应采取合理措施维护其密码和帐号的安全。用户对利用该密码和帐号所进行的一
						切活动负全部责任；由该等活动所导致的任何损失或损害由用户承担，答设不承担任何责任。
						<br />
						&emsp;&emsp;（6）用户的密码和帐号遭到未授权的使用或发生其他任何安全问题，用户可以立即通知答
						设客服，并且用户在每次连线结束，应结束帐号使用，否则用户可能得不到答设平台的安全 保护。
						<br />
						&emsp;&emsp;（7）对于用户长时间未使用的帐号，答设平台有权予以关闭。
						<br />
						&emsp;&emsp;2、用户发布内容的传播
						<br />
						&emsp;&emsp;（1）您理解并同意，根据《中华人民共和国网络安全法》《互联网信息内容生态治理规定》
						《互联网信息服务管理办法》《移动互联网应用程序信息服务管理规定》等法律法规，答设
						有权采用任何答设认为有必要之措施，对您上传、分享、评论等行为产生的内容进行审查，并采用包括但不限于屏蔽、删除、修改等措施以保证信息呈现结果符合法律法规要求，维护
						清朗网络空间。
						<br />
						&emsp;&emsp;（2）如果您通过本服务分享作品，则您保证： a 您所分享的作品均由您本人原创或已经依法获得原始权利人合法授权。
						b 您有权授权答设依据本服务的相关规则使用您的作品，包括但不限于将您上传的图片 向其他用户推荐或推广。 c
						您所上传的图片符合国家相关法律法规和公序良俗，不会损害任何他方的合法权益或 造成不良社会影响。 d
						答设依照本服务的规则使用您的作品，无须另行获得第三方同意或对第三方承担任何 责任。
						<br />
						&emsp;&emsp;3、禁止用户制作、复制、发布含有下列内容的违法信息：
						<br />
						&emsp;&emsp;（1）反对宪法所确定的基本原则的；
						<br />
						&emsp;&emsp;（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
						<br />
						&emsp;&emsp;（3）损害国家荣誉和利益的；
						<br />
						&emsp;&emsp;（4）歪曲、丑化、亵渎、否定英雄烈士事迹和精神，以侮辱、诽谤或者其他方式侵害英雄
						烈士的姓名、肖像、名誉、荣誉的；
						<br />
						&emsp;&emsp;（5）宣扬恐怖主义、极端主义或者煽动实施恐怖活动、极端主义活动的；
						<br />
						&emsp;&emsp;（6）煽动民族仇恨、民族歧视，破坏民族团结的；
						<br />
						&emsp;&emsp;（7）破坏国家宗教政策，宣扬邪教和封建迷信的；
						<br />
						&emsp;&emsp;（8）散布谣言，扰乱社会秩序，破坏社会稳定的；
						<br />
						&emsp;&emsp;（9）散布淫秽、赌博、暴力、凶杀、自残、恐怖或者教唆犯罪的；
						<br />
						&emsp;&emsp;（10）侮辱或者诽谤他人，侵害他人合法权益（如名誉权、肖像权等）的；
						<br />
						&emsp;&emsp;（11）侵害未成年人合法权益或者损害未成年人身心健康的
						<br />
						&emsp;&emsp;（12）侵害他人知识产权、商业秘密等合法权利的；
						<br />
						&emsp;&emsp;（13）虚构事实、隐瞒真相以误导、欺骗他人的；
						<br />
						&emsp;&emsp;（14）发布、传送、传播广告信息及垃圾信息的；
						<br />
						&emsp;&emsp;（15）其他法律法规禁止的行为。
						如果您上传、发布或传输的内容含有以上违反法律法规的信息或内容，或者侵犯任何第三方
						的合法权益，您将直接承担以上导致的一切不利后果。
						<br />
						&emsp;&emsp;4、用户同意遵守答设规则、常见问题解答等答设管理规定。
						<br /> <br />
						<strong>五、关于个人信息保护</strong>
						<br />
						答设深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。在您使用答设提
						供的产品或服务的过程中，您同意按照答设发布的隐私政策收集、使用、存储、共享、披露
						和保护您的个人信息。如答设网站、客户端或关联公司的网站、客户端等未设独立隐私政策的，则其将参照适用答设隐私政策要求保护您的个人信息。若其向您提供的产品或服务单独
						设立隐私政策的，请在注册答设用户前，仔细阅读答设隐私政策，以了解答设如何处理和保 护您的个人信息。
						<br /> <br />
						<strong>六、版权声明</strong>
						<br />
						本平台（以下简称“平台”）是一个为用户提供内容分享、交流和学习的网络服务平台。平
						台尊重并保护所有使用平台服务用户的知识产权，同时也要求用户在使用平台服务时同样尊
						重并保护他人的知识产权。为此，平台制定了以下版权声明，以规范用户在平台上传、发布、 传播和使用内容的行为。
						<br />
						&emsp;&emsp;1. 用户在平台上传、发布或传播的任何内容（包括但不限于文字、图片、音频、视频等），
						均应保证其拥有合法的著作权或已经取得相关权利人的合法授权，并且不侵犯任何第三方的
						合法权益。用户对其上传、发布或传播的内容承担全部责任，与平台无关。
						<br />
						&emsp;&emsp;2. 平台有权根据法律法规、政策规定、用户协议或社区规范等，对用户上传、发布或传播
						的内容进行审核、删除、屏蔽或采取其他必要措施，以维护平台的正常运营和其他用户的合 法权益。平台对此不承担任何责任。
						<br />
						&emsp;&emsp;3. 用户在平台上传、发布或传播的内容，除非与平台另有书面约定，否则均视为授予平台
						及其关联公司非独家、免费、永久、全球性的许可，允许平台及其关联公司在现有或未来的
						任何媒介、形式或技术上使用、复制、修改、改编、翻译、分发、展示和表演该内容，以及 制作衍生作品和进行商业开发。
						<br />
						&emsp;&emsp;4. 用户在使用平台服务时，应遵守相关法律法规，尊重他人的知识产权，不得擅自复制、
						下载、传播或使用他人在平台上传、发布或传播的受知识产权保护的内容，除非已经取得相
						关权利人的合法授权。如用户发现自己的知识产权被侵犯，请及时向平台提交书面通知，平台将根据相关法律法规和程序处理该通知。
						<br />
						&emsp;&emsp;5. 本版权声明构成用户与平台之间的协议的一部分，具有同等法律效力。如本版权声明与
						用户协议或其他协议存在冲突，以本版权声明为准。如本版权声明中的任何条款被认定为无
						效或不可执行，不影响其他条款的有效性和可执行性。
						<br /> <br />
						<strong>七、免责声明</strong>
						<br />
						&emsp;&emsp;1、除答设注明的服务条款外，其它因使用答设而导致任何意外、疏忽、合约毁坏、诽谤、
						版权或知识产权侵犯及其所造成的各种损失（包括因下载而感染电脑病毒），答设概不负责， 亦不承担任何法律责任。
						<br />
						&emsp;&emsp;2、答设不保证（包括但不限于）：
						<br />
						&emsp;&emsp; （1）本网站适合用户的使用要求；
						<br />
						&emsp;&emsp; （2）本网站不受干扰，及时、安全、可靠或不出现错误；
						<br />
						&emsp;&emsp; （3）用户经由本网站取得的任何产品、服务或其他材料符合用户的期望。
						<br />
						&emsp;&emsp;3、本服务可能会包含与其他网站或资源的链接，即第三方链接。答设对于前述网站或资源
						的内容、隐私政策和活动，无权控制、审查或修改，因而也不承担任何责任。建议您在离开
						答设服务，访问其他网站或资源前仔细阅读其服务条款和隐私政策。
						<br />
						&emsp;&emsp;4、因用户内容在本网站的上载或发布，而导致任何第三方提出索赔要求或衍生的任何损害
						或损失，由用户承担全部责任，答设不承担任何责任。
						<br />
						&emsp;&emsp;5、答设平台内所发言论仅代表网友自己，并不反映任何答设之意见及观点。
						<br />
						&emsp;&emsp;6、答设认为，一切网民在进入答设主页及各层页面时已经仔细看过本条款并完全同意。
						<br /> <br />
						<strong>八、法律适用和管辖</strong>
						<br />
						&emsp;&emsp;1、 本协议的生效、履行、解释及争议的解决均适用中华人民共和国法律。本条款因与中
						华人民共和国现行法律相抵触而导致部分无效，不影响其他部分的效力。双方同意，解决争
						议时，应以您同意的最新版本的《用户协议》为准。
						<br />
						&emsp;&emsp;2、 如就本协议发生任何争议，双方应本着相互谅解、信任、 理解、支持和诚信平等、互
						惠互利的原则，协商解决。协商不成时任何一方均可向 武汉市江夏区人民法院提起诉讼。
					</div>
				</div>
			</Modal>
		</div>
	);
};

const mapDispatchToProps = {};
export default connect(null, mapDispatchToProps)(Register);
