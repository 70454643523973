
import http from "@/api";
import { apiUrl } from "@/config/config";

export const userInfo = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/user/detail`, params);
};
export const userSave = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/userInfo/save`, params);
};
export const userInfoDecode = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/userInfo/decode`, params);
};
export const userInfoResult = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/userInfo/result`, params, { headers: { noLoading: true } });
};
export const userInfoAuthLogo = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/userInfo/authLogo`, params);
};
export const enterpriseInfoAuthLogo = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/enterpriseInfo/authLogo`, params);
};
export const enterpriseInfoDecode = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/enterpriseInfo/decode`, params);
};
export const enterpriseInfoResult = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/enterpriseInfo/result`, params, { headers: { noLoading: true } });
};
export const enterpriseInfoSave = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/enterpriseInfo/save`, params);
};
export const orderPage = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/order/page`, params);
};
export const orderInfo = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/order/info`, params);
};
export const orderPeriod = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/order/period`, params);
};
export const evidenceAndCollOrderDetail = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/v1/evidenceColl/evidenceAndCollOrderDetail`, params);
};
export const collOrderDetail = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/evidenceDetail/info`, params);
};
export const evidenceCollValue = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/v1/tariff/value`, params);
};
export const evidenceCollDelete = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/v1/evidenceColl/delete`, params);
};
export const evidenceCollCancel = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/evidenceColl/cancel`, params);
};
export const evidenceCollList = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/evidenceColl/listPage`, params);
};
export const evidenceSave = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/evidenceColl/save`, params);
};
export const evidenceFindById = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/evidenceDetail/info`, params);
};
export const evidenceCert = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/evidenceRecordAndCollRecord/cert`, params);
}
export const updateCollStatus = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/evidenceColl/updateCollStatus`, params);
};
export const collFileUpdateStatus = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/v1/evidenceColl/collFileUpdateStatus`, params);
};
//上传阿里云oss
export const policy = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/oss/policy/web`, params);
};
//存证相关
export const evidencePage = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/evidence/page`, params);
};
export const evidenceInfo = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/evidence/info`, params);
};
export const evidenceAdd = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/evidence/add`, params);
};
export const evidenceExec = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/evidence/detail/exec`, params);
};
export const evidenceDel = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/evidence/del`, params);
};
export const evidenceCancel = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/evidence/cancel`, params);
};
export const evidenceDetail = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/evidence/detail`, params);
};
export const evidenceGetDetail = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/evidence/getDetail`, params);
};
export const typeEvidence = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/evidence/type/evidence`, params);
};
export const typeEvidenceColl = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/evidence/type/evidenceColl`, params);
};
export const evidenceCollGetDetail = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/evidenceColl/getDetail`, params);
};
export const userUpdate = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/user/update`, params);
};
export const questionList = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/api/question/list`, params);
};
export const themeInfo = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/api/theme/info`, params);
};
export const packagePage = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/package/page`, params);
};
export const productInfo = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/product/info`, params);
};
export const productPage = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/product/page`, params);
};
export const userPackageServiceCashier = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/userPackageService/cashier`, params);
};
export const userPackageServicedeDuction = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/userPackageService/deduction`, params);
};
export const userPackageServiceInfo = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/userPackageService/info`, params);
};
export const userPackageServiceList = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/userPackageService/list`, params);
};
export const userPackageServicePurchase = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/userPackageService/purchase`, params);
};
export const evidenceCashier = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/evidence/cashier`, params);
};
export const evidenceCollCashier = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/evidenceColl/cashier`, params);
};
export const userPackageServiceMineList = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/userPackageService/mineList`, params);
};
export const lawyerInsert = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/api/lawyer/insert`, params);
};
export const lawyerPage = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/api/lawyer/page`, params);
};

export const monitorAdd = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/monitor/add`, params);
};
export const monitorCashier = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/monitor/cashier`, params);
};
export const monitorCease = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/monitor/cease`, params);
};
export const monitorDel = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/monitor/del`, params);
};
export const monitorInfo = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/monitor/info`, params);
};
export const monitorPage = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/monitor/page`, params);
};
export const evidenceWatermarkFileList = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/evidence/watermarkFileList`, params);
};
export const monitordetailpage = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/monitor/detail/page`, params);
};
export const v1listPage = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/api/sys/dic/v1/listPage`, params);
};
export const areaprovinceList = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/api/area/provinceList`, params);
};
export const areacityList = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/api/area/cityList`, params);
};
export const monitormonitorRenewrenewDetail = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/monitor/monitorRenew/renewDetail`, params);
};
export const monitormonitorRenewrenew = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/monitor/monitorRenew/renew`, params);
};
export const monitormonitorRenewcashier = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/monitor/monitorRenew/cashier`, params);
};
export const monitordayAndPrice = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/monitor/dayAndPrice`, params);
};
export const intentadd = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/api/intent/add`, params);
};
export const usercheckLogin = () => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/user/checkLogin`);
};
export const spacelist = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/v1/space/list`, params);
};
export const userSpacecashier = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/v1/userSpace/cashier`, params);
};
export const userSpaceinfo = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/v1/userSpace/info`, params);
};
export const userSpacelist = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/v1/userSpace/list`, params);
};
export const userSpacepurchase = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/v1/userSpace/purchase`, params);
};
export const userSpacefilePage = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/v1/userSpace/filePage`, params);
};
export const monitorwordCheck = (params: any) => {
    return http.post<Menu.MenuOptions[]>(apiUrl + `/monitor/wordCheck `, params);
};
