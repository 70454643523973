import { Button, Dropdown, MenuProps, Modal, Space, Tabs, TabsProps, message } from "antd";
import Index from "./components";
import "./index.less";
import { useEffect, useState } from "react";
import { store } from "@/redux/index";
import logo from "@/assets/images/logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import HelpCenter from "./components/helpCenter";
import PackagePrice from "./components/packagePrice";
import { CaretDownOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { HOME_URL } from "@/config/config";
import { connect } from "react-redux";
import { setToken } from "@/redux/modules/global/action";
import LegalSupport from "./components/legalSupport";
import { usercheckLogin } from "@/api/modules/business";

const Home = (props: any) => {
	const { setToken } = props;
	const [type, setType] = useState("1");
	const [servieType, setServieType] = useState("1");
	const { state } = useLocation();
	const navigate = useNavigate();
	// const changeType = (option: any) => {
	// 	if (option.type) setType(option.type);
	// };
	useEffect(() => {
		if (state == "2") {
			setType("2");
			setServieType("1");
		}
		if (state == "3") {
			setType("2");
			setServieType("2");
		}
		if (window.location.href.split("?")[1] == "state=3") {
			setType("2");
			setServieType("2");
		}
	}, []);

	const goto = type => {
		if (type == "1") {
			navigate("/login");
		} else {
			navigate("/login", { state: "2" });
		}
	};
	const onChange = (key: string) => {
		setType(key);
	};
	const goHome = () => {
		navigate("/");
	};

	const items0: TabsProps["items"] = [
		{
			key: "1",
			label: `首页`
		},
		{
			key: "2",
			label: `服务购买`
		},
		{
			key: "4",
			label: `法律支持`
		},
		{
			key: "3",
			label: `帮助中心`
		}
	];
	const items: MenuProps["items"] = [
		{
			label: "进入工作台",
			key: "3",
			onClick: () => handleMenuClick(0)
		},
		{
			label: "账户管理",
			key: "4",
			onClick: () => handleMenuClick(1)
		},
		{
			label: "退出登录",
			key: "1",
			onClick: () => handleMenuClick(2)
		},
		{
			label: "返回",
			key: "2",
			onClick: () => handleMenuClick(3)
		}
	];
	const handleMenuClick = async (num: number) => {
		if (num == 0) {
			let A: any = await usercheckLogin();
			if (A.code == 200) {
				navigate("/existingEvidence");
			} else {
				emptytoken();
				navigate("/login");
			}
		} else if (num == 1) {
			let A: any = await usercheckLogin();
			if (A.code == 200) {
				navigate("/account");
			} else {
				emptytoken();
				navigate("/login");
			}
		} else if (num == 2) {
			logout();
		} else if (num == 3) {
			navigate("/");
		}
	};
	const logout = () => {
		Modal.confirm({
			title: "温馨提示",
			icon: <ExclamationCircleOutlined />,
			content: "是否确认退出登录？",
			okText: "确认",
			cancelText: "取消",
			onOk: () => {
				emptytoken();
				message.success("退出登录成功！");
				navigate(HOME_URL);
			}
		});
	};
	const emptytoken = () => {
		setToken("");
	};
	return (
		<div className="homePage">
			<div className="navbar">
				<div>
					<div>
						<div onClick={goHome}>
							<img className="login-icon" src={logo} alt="logo" />
							<span className="logo-text">答设版权保护平台</span>
						</div>
					</div>
					<div>
						<Tabs activeKey={type} items={items0} onChange={onChange} />
						{!store.getState().global.token ? (
							<>
								<Button size="large" onClick={() => goto("2")} className="left">
									注册
								</Button>
								<Button type="primary" size="large" onClick={() => goto("1")}>
									登录
								</Button>
							</>
						) : (
							<Dropdown menu={{ items }} trigger={["click"]} className="marginLeft30">
								<a onClick={e => e.preventDefault()}>
									欢迎您，{store.getState().global.user ? store.getState().global.user.userName : ""}
									<Space>
										<CaretDownOutlined />
									</Space>
								</a>
							</Dropdown>
						)}
					</div>
				</div>
			</div>
			{type == "1" ? <Index emptytoken={emptytoken} /> : ""}
			{type == "2" ? <PackagePrice servieType={servieType} /> : ""}
			{type == "3" ? <HelpCenter /> : ""}
			{type == "4" ? <LegalSupport /> : ""}
		</div>
	);
};

const mapDispatchToProps = { setToken };
export default connect(null, mapDispatchToProps)(Home);
