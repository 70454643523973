import { useEffect, useRef, useState } from "react";
import "./index.less";
import { WechatOutlined,PayCircleOutlined,BankOutlined } from "@ant-design/icons";
import { payQuery, toPay, agreementQuery} from "@/api/modules/login";
import {Spin, message, Button} from "antd";
import SignContract from "@/components/cashier/SignContract";

const Cashier = (props: any) => {
	const { orderCode, closeModal } = props;
	const [payType, setPayType] = useState(1);
	const [bankList, setBankList] = useState( [{name:'PSBC',cnName:'邮储银行',contractNo:null}])
	const [bankLoading,setBankLoading] = useState(false)
	const [bankPayLoading, setBankPayLoading] = useState(false)
	const signRef = useRef(null)

	const [codeUrl, setCodeUrl]: any = useState("");
	const [countDown, setCountDown]: any = useState("10 分 00 秒");
	const setI = useRef(null);
	const setT = useRef(null);

	useEffect(() => {
		getCode(orderCode, payType);

		return () => {
			clearInterval(setI.current);
			clearInterval(setT.current);
		};
	}, [orderCode]);

	useEffect(()=>{
		if(payType!==2)return
		clearInterval(setI.current);
		clearInterval(setT.current);
		getAgreeStatus()
	},[payType])

	const getAgreeStatus= ()=>{
		setBankLoading(true)
		const promiseList = bankList.map(bank=>{
			return new Promise((resolve,reject)=>{
				agreementQuery({eooCode:bank.name}).then((res:any)=>{
					bank.contractNo = res.data?.contractNo
					resolve(null)
				}).catch(()=>{
					reject()
				})
			})
		})
		Promise.all(promiseList).then(()=>{
			setBankList([...bankList])
			setBankLoading(false)
		}).catch(()=>{
			setBankLoading(false)
		})
	}

	useEffect(()=>{
		if(payType!==1)return
		getCode(orderCode,payType)
	},[payType])

	const getCode = async (o, p) => {
		const { data }: any = await toPay({ orderCode: o, payType: p });
		if (data) {
			setCodeUrl(data.base64);
			let num = 600;
			clearInterval(setI.current);
			clearInterval(setT.current);
			setI.current = setInterval(() => {
				num--;
				let m = Math.floor(num / 60);
				let s = num % 60;
				let str = `${m} 分 ${s} 秒`;
				setCountDown(str);
				if (num === 0) {
					clearInterval(setI.current);
					getCode(o, "1");
				}
			}, 1000);
			payResult(data.payCode)
		}
	};

	const payResult = (payCode)=>{
		setT.current = setInterval(async () => {
			payQuery({ payCode: payCode }).then(res=>{
				const { data }:any = res
				if (data.payStatus == "0013") {
					message.success("购买成功");
					closeModal();
				} else if (data.payStatus == "0012") {
					message.error("支付失败,请重新支付")
					clearInterval(setT.current);
					if(payType===1){
						getCode(orderCode,payType)
					}else if(payType===2){
						setBankPayLoading(false)
					}
				}
			}).catch(()=>{
				setBankPayLoading(false)
			})
		}, 3000);
	}

	const handleBankPay = (bank)=>{
		setBankPayLoading(true)
		toPay({
			contractNo: bank.contractNo,
			eooCode: bank.name,
			orderCode: orderCode,
			payType: payType,
		}).then((res:any)=>{
			if(res.data.errorMsg){
				setBankPayLoading(false)
				message.error(res.data.errorMsg)
			}else{
				payResult(res.data.payCode)
			}
		}).catch(()=>{
			setBankPayLoading(false)
		})
	}

	// @ts-ignore
	return (
		<>
			<div className="componentsCashier">
				<div>
					<div className="c-t">
						<div></div>
						支付方式
					</div>
					<div className={`c-f weixin ${payType===1?'active':''}`} onClick={()=>{!bankPayLoading&&setPayType(1)}}>
						<div>
							<div>
								<WechatOutlined />
							</div>
							<div>微信支付</div>
						</div>
					</div>
					<div className={`c-f digirmb ${payType===2?'active':''}`} onClick={()=>{setPayType(2)}}>
						<div>
							<div>
								<PayCircleOutlined />
							</div>
							<div>数字人民币支付</div>
						</div>
					</div>
					{payType===1?<div className="c-l-l">
						<div className="d-1">支付剩余时间：{countDown}</div>
					</div>:''}
				</div>
				<div>
					{payType===1?<div className="c-l-r">
						<div>{codeUrl ? <img src={"data:image/png;base64," + codeUrl} alt="" /> : <Spin size="large" />}</div>
						<span>请打开微信扫描图中二维码完成支付</span>
					</div>:
					<div className="c-l-rr">
						<span>请选择用于支付的数字人民币钱包</span>
						<Spin spinning={bankLoading}>
							{
								bankList.map(bank=>{
									return <div key={bank.name} className="bank-item">
										<BankOutlined />
										<span>{ bank.cnName }</span>
										{ bank.contractNo?<span className="signed">已签约</span>:
											<Button onClick={()=>{
												signRef.current.openModal(bank)
											}} style={{fontSize:'11px'}} size="small" type="primary">立即签约</Button> }
									</div>
								})
							}
							{bankList[0].contractNo?<div style={{display:'flex',justifyContent:'center',marginTop:'10px'}}>
								<Button loading={bankPayLoading} onClick={()=>{handleBankPay(bankList[0])}} type='primary'>立即支付</Button>
							</div>:''}
						</Spin>
					</div>}
				</div>
			</div>
			<SignContract ref={signRef} getAgreeStatus={getAgreeStatus}></SignContract>
		</>
	);
};

export default Cashier;
